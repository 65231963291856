<template>
  <div class="chart" :id="`chart_${chartId}`" :style="{width: width, height: height}"/>
</template>

<script>
  export default {
    name: 'chart-line',
    props: {
      width: {type: String, default: '100%'},
      height: {type: String, default: '270px'},
      title: {type: String, default: ''},
      legend: {type: Array, default: () => []},
      category: {type: Array, default: () => []},
      data: {type: Array, default: () => []},

      color: {type: Array, default: () => ['#0177FB', "#F1C35D", '#1890ff','#CCCCCC']},
      type: {type: String, default: 'line'}, // bar | line | area
      smooth: {type: Boolean, default: false},
      barWidth: {type: String},

      markPoint:{type: String},

      direction: {type: String, default: 'vertical'}, // 水平 horizontal | 垂直 vertical

      xAxisLabelRotate: {type: Number, default: 0},
      xAxisTick: {type: Object, default: () => ({})}, // 刻度
      xAxisLine: {type: Object, default: () => ({})}, // 横坐标
      xTextStyle: {type: Object, default: () => ({color: '#FFFFFF'})}, // 坐标轴文字样式

      itemStyle: {type: Object, default: () => ([])}, // 柱状图样式

      ySplitLine: { type: Object, default: () => {} }, // 分割线
      interval: { type: Number }, // 刻度间隔
      axisLabelShow: {type: Boolean, default: true}, // 是否展示纵坐标数值
      axisTickShow: {type: Boolean, default: true}, // 是否展示纵坐标刻度
      stack: {type: Boolean, default: false},
      showLegend: {type: Boolean, default: false},
      showDateLabel: {type: Boolean, default: true},
      formatLabel: {type: Function},

      showTooltip: {type: Boolean, default: true},
      formatTooltip: {type: Function},
    },
    data() {
      return {
        chartId: Math.round(Math.random() * 1000000),
        chart: null,
      }
    },
    computed: {
      series() {
        let data = [];
        this.data.forEach((d, i) => data.push({
          name: this.legend[i] ?? '',
          type: this.type === 'area' ? 'line' : this.type,
          data: d,
          smooth: this.smooth,
          areaStyle: this.type === 'area' ? {} : null,
          barWidth: this.barWidth,
          label: {
            show: this.showDateLabel,
            position: 'insideBottom',
            formatter: (params) => (typeof this.formatLabel === 'function' ? this.formatLabel(params) : params.value) || '',
          },
          itemStyle: this.itemStyle ?? {},
          stack: this.stack ? 'stack' : undefined,
        }));
        return data;
      },
    },
    watch: {
      label(){
        this.setOption();
      },
      data() {
        this.setOption();
      },
    },
    methods: {
      setOption() {
        // 初始化未完成
        if(!this.chart){
          return true;
        }

        // 指定图表的配置项和数据
        let option = {
          title: {
            text: this.title
          },
          color: this.color,
          grid: {
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          series: this.series,
        };
        // 鼠标移上时显示
        if(this.showTooltip){
          option.tooltip = {trigger: 'item'};
          if(typeof this.formatTooltip === 'function'){
            option.tooltip.formatter = params => this.formatTooltip(params) || '';
          }else if(typeof this.formatTooltip === 'string'){
            option.tooltip.formatter = this.formatTooltip;
          }
        }

        // 图示 | 图例
        if(this.showLegend){
          option.legend = {
            data: this.legend,
            right: 0,
          }
        }else{
          option.grid.top = '3%';
        }

        const categoryOption = {
          type: 'category',
          data: this.category,
          axisLabel: {
            rotate: this.xAxisLabelRotate,
            width: 20,
            hideOverlap: false,
            textStyle: this.xTextStyle
          },
        };
        let valueOption = {
          type: 'value',
          axisLabel: {
            textStyle: this.xTextStyle,
          },
        };
        if(this.direction === 'vertical'){
          valueOption = {
            ...valueOption,
            axisLabel: {
              show: this.axisLabelShow,
              textStyle: this.xTextStyle,
            },
            axisTick: {
              show: this.axisTickShow,
            },
            splitLine: this.ySplitLine,
            interval: this.interval,
          }
          categoryOption.axisTick = this.xAxisTick;
          categoryOption.axisLine = this.xAxisLine;
          option.xAxis = categoryOption;
          option.yAxis = valueOption;
        }else{
          option.xAxis = valueOption;
          option.yAxis = categoryOption;
        }

        // 使用刚指定的配置项和数据显示图表。
        this.chart.setOption(option);
      },



    },
    mounted() {
      // 基于准备好的dom，初始化echarts实例
      const chartDom = document.getElementById(`chart_${this.chartId}`);
      this.chart = this.$echarts.init(chartDom);
      this.setOption();
    }
  }
</script>
