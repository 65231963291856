<template>
  <img :src="`static/svgIcon/${name}.svg`" :width="size || width" :height="size || height" :alt="name">
</template>

<script>
export default {
  name: 'svgIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 24,
      validator (value) {
        return value > 0;
      }
    },
    height: {
      type: [Number, String],
      default: 24,
      validator (value) {
        return value > 0;
      }
    },
    size: {
      type: [Number, String],
      validator(value) {
        return value > 0;
      }
    }
  },
};
</script>

<style scoped lang="scss">

</style>
