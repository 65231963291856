<template>
  <div class="db_device_bg">
    <div class="db_device" :class="{inline: value.status === 1}">
      <div class="db_device_img">
        <a-image width="136px" height="153px" :src="value.img" />
      </div>
      <a-flex justify="space-between" align="center" class="db_device_info">
        <div class="ddi_name">{{ value.dishes }}</div>
        <a-flex align="center">
          <svg-icon name="fire" size="18" />
          <span class="ddi_power">{{ value.power }}</span>
        </a-flex>
      </a-flex>
      <a-flex justify="space-between" align="center" class="db_device_num">
        <span class="ddn_label">已产出菜</span> {{ value.num }}
      </a-flex>
      <div class="db_device_index">
        {{ getIndex(index) }}
      </div>
      <div class="db_device_status" :class="{inline: value.status === 1, offline: value.status === 2}">
        <div class="status" />
        {{ value.status === 1 ? '在线' : '离线' }}
      </div>
    </div>
  </div>
</template>

<script>
// 设备
import SvgIcon from '@/components/base/svgIcon.vue';

export default {
  name: 'dashboardDevice',
  components: { SvgIcon },
  props: {
    value: {
      type: Object, default: () => ({}),
    },
    index: Number
  },
  methods: {
    getIndex(index) {
      return index < 10 ? `00${index}` : index < 100 ? `0${index}` : index;
    }
  }
};
</script>

<style scoped lang="scss">
.db_device_bg{
  background-image: linear-gradient(to bottom, rgba(59, 59, 67, 1), rgba(18, 18, 26, 1));
  padding: 1px;
  border-radius: 24px;
  width: 215px;
  height: 268px;

}
.db_device {
  width: 213px;
  height: 266px;
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 24px;
  position: relative;
  background: #0A0A14;

  &.inline {
    background-image: radial-gradient(100% 85% at 50% 40%, #010214 47%, #584097 100%);
  }

  .db_device_img{
    height: 153px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .db_device_info{
    gap: 4px;
    color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 140px;
    left: 12px;
    right: 12px;
    height: 38px;
    border-radius: 12px;
    border: 1px solid rgba(119, 122, 150, 0.1);
    background: rgba(1, 2, 20, 0.1);
    padding-inline: 18px;
    .ddi_name{
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 15px;
    }

    .ddi_power{
      margin-left: 6px;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 15px;
    }
  }

  .db_device_name{
    margin-top: 6px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 22px;
  }

  .db_device_tags{
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 8px;

    .db_device_tags_item{
      font-size: 12px;
      color: #FFFFFF;
      line-height: 20px;
      padding-inline: 6px;
      background: #636890;
      border-radius: 6px;
    }
  }

  .db_device_num{
    margin-top: 10px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 16px;
    .ddn_label {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .db_device_btns{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 18px;

    .db_device_btns_item{
      width: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      img{
        width: 28px;
        height: 28px;
      }
      span {
        font-size: 11px;
        color: #FFFFFF;
        margin-top: 5px;
        line-height: 13px;
        opacity: 0.6;
      }
    }
  }

  .db_device_index{
    margin-top: 24px;
    font-weight: 600;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 21px;
  }

  .db_device_status{
    position: absolute;
    width: 68px;
    height: 28px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 4px 12px;
    top: 18px;
    left: 18px;
    font-size: 13px;

    .status{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 6px;
    }
    &.inline {
      background: rgba(255, 255, 255, 0.06);
      .status{
        background: #70C787;
      }
    }

    &.offline{
      background: #EC4B54;
      .status{
        background: #F3F2E9;
      }
    }
  }
}
</style>
