<template>
  <div class="portal-header">
    <div class="portal-header-left">
      <a-popover v-model:open="openMenu" placement="bottomLeft">
        <template #content>
          <a-row class="portal-header-menu">
            <template v-for="portalMenu in portalMenuList">
              <a-col :span="12">
                <div class="portal-header-menu-block" @click="goPage(portalMenu)">
                  <dol-icon class="menu-icon" :type="portalMenu.icon" />
                  <div class="menu-text">{{ $dt(portalMenu,'name','nameEn') }}</div>
                </div>
              </a-col>
            </template>
            <a-col :span="12">
              <div class="portal-header-menu-block" @click="goManager" v-if="$auth('MODULE_MANAGER')">
                <dol-icon class="menu-icon" type="ManagerEnter"/>
                <div class="menu-text">{{$t('后台管理')}}</div>
              </div>
            </a-col>
          </a-row>
        </template>
        <img class="menu-icon" src="../../../public/static/icon/portal/systemLogo.svg"/>
      </a-popover>
      <div class="header-title">
        <span class="main-title" @click="goHome">{{ title }}</span>
        <span class="header-sub-title" v-if="subTitle">{{ $dt(subTitle,'name','nameEn')}}</span>
      </div>
    </div>
    <div class="portal-header-right">
      <dol-switch-lang />
      <a-dropdown class="account">
        <span class="account-info">
          <img src="../../../public/static/icon/icon.png" class="account-icon" alt="Avatar">
          <span>{{ userInfo.name }}</span>
          <dol-icon type="caret-down-filled" />
        </span>
        <template #overlay>
          <a-menu @click="accountMenu">
            <a-menu-item key="changePwd">
              修改密码
            </a-menu-item>
            <a-menu-item key="logout">
              退出登录
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <a-modal title="修改密码" v-model:open="passwordDialog" okText="确认" cancelText="取消" :confirmLoading="changePwdLoading" @ok="changePwd">
      <a-form class="dol-edit-form">
        <a-form-item label="旧密码"><a-input v-model:value="passwordForm.oldPassword" type="password"/></a-form-item>
        <a-form-item label="新密码"><a-input v-model:value="passwordForm.newPassword" type="password"/></a-form-item>
        <a-form-item label="确认密码"><a-input v-model:value="passwordForm.confirmPassword" type="password"/></a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutMenuSearch from '@/layout/sideMenuLayout/layout-menu-search';

export default {
  name: "portal-header",
  components: { LayoutMenuSearch},
  data(){
    return {
      openMenu: false,

      passwordDialog: false,
      passwordForm: {},
      changePwdLoading: false,
    }
  },
  computed: {
    ...mapGetters(['menuList', 'userInfo', 'langType', "tenantInfo"]),
    portalMenuList(){
      return this.menuList.filter(item => item.layout === 'portalLayout' && item.pageCode !== 'portalIndex');
    },
    title(){
      return this.tenantInfo ? this.tenantInfo.name : process.env.VUE_APP_WEB_TITLE;
    },
    subTitle(){
      const matchPage = this.menuList.filter(item => item.layout === 'portalLayout').find(item => item.fullPath === this.$route.path);
      if(matchPage && matchPage.pageCode !== 'portalIndex'){
        return matchPage.name;
      }else{
        return undefined;
      }
    },
  },
  methods: {
    goPage(page){
      this.$router.push({name: page.pageCode});
      this.openMenu = false;
    },
    goHome(){
      this.$router.push('/portal');
    },
    goManager(){
      this.$router.push('/');
      this.openMenu = false;
    },

    accountMenu({ key }) {
      switch (key) {
        case 'changePwd':
          this.passwordForm = {};
          this.passwordDialog = true;
          break;
        case 'logout':
          this.$router.replace('/login').then(() => {
            this.$store.dispatch('user/logout').then(() => {
            });
          });
          break;
      }
    },
    changePwd(){
      if(this.passwordForm.oldPassword && this.passwordForm.newPassword && this.passwordForm.confirmPassword){
        if(this.passwordForm.newPassword === this.passwordForm.confirmPassword){
          this.changePwdLoading = true;
          this.$ajax({
            url: this.$api.system.auth.user.changePwd,
            method: 'post',
            data: this.passwordForm,
          }).then(result => {
            this.changePwdLoading = false;
            this.$message.info(result.message);
            this.passwordDialog = false;
          }).catch(e => {
            this.changePwdLoading = false;
          })
        }else{
          this.$message.info('两次密码输入不一致');
        }
      }else{
        this.$message.info('密码不能为空');
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/constant';
.portal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);

  .portal-header-left{
    display: flex;
    align-items: center;
    .menu-icon{
      min-width: 24px;
      max-width: 120px;
      height: 24px;
    }
    .header-title{
      font-size: 18px;
      font-family: 'Microsoft Ya Hei', 'sans-serif';
      font-weight: 700;
      margin-left: 14px;

      .main-title{
        cursor: pointer;
        &:hover{
          color: $primaryColor;
        }
      }

      .header-sub-title{
        font-size: 14px;
        color: #666666;
        margin-left: 16px;
      }
    }
  }

  .portal-header-right{
    display: flex;
    align-items: center;

    .account-info{
      display: flex;
      align-items: center;

      img.account-icon{
        height: 24px;
        margin-right: 10px;
      }
      .dol-icon{
        font-size: 10px;
        margin-left: 2px;
      }
    }
  }
}
.portal-header-menu{
  width: 300px;
  .portal-header-menu-block{
    border-radius: 4px;
    padding: 18px;
    cursor: pointer;
    text-align: center;

    &:hover{
      opacity: 0.8;
    }

    .menu-icon{
      font-size: 36px;
    }
    .menu-text{
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      flex: 1;
    }
  }
}
</style>
