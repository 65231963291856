// created by wangshuyi on 2022/9/1
import { ajax } from "dolphin-framework-vue";
// import { whiteList } from '@/router/routeConfig';
import * as dd from 'dingtalk-jsapi';
const whiteList = [
  /^\/login/, /^\/403/
];
import router from '@/router/index';

'use strict';
const state = {
  token: null,
  userInfo: null,
  tenantInfo: null,
  menuList: [],
  pageList: [],
  auth: [],
};

const mutations = {
  saveUserInfo(state, { token, userInfo, auth, menu, page }) {
    if(token){
      state.token = token;
    }
    state.userInfo = userInfo;
    state.tenantInfo = userInfo.tenant;
    state.menuList = menu;
    state.pageList = page;
    state.auth = auth;

    this.commit('app/initRouteConfig');
    this.commit('app/session');
  }
};

const actions = {
  hasLogin({ state }) {
    return state.userInfo ? Promise.resolve() : Promise.reject();
  },
  whiteList({}, path) {
    let flag = false;
    whiteList.forEach(pathRule => {
      if (pathRule.test(path)) {
        flag = true;
      }
    });
    return flag ? Promise.resolve() : Promise.reject();
  },

  logout({ state }) {
    // todo 退出
    // ajax({
    //   url: '/login/logout',
    //   method: 'post',
    //   data: {token: state.token},
    // }).then(({ message }) => {
    //   console.log(message);
    // });

    state.token = null;
    state.userInfo = null;
    state.menuList = [];
    state.auth = [];
    state.page = [];
    this.state.app.menuTab = [];
    this.state.app.menuActive = {};
    this.commit('app/session');
  },

  login({ commit }, loginForm) {
    return ajax({
      url: '/auth/login',
      method: 'post',
      data: loginForm,
    }).then(({ data: token }) => {
      return ajax({
        url: '/auth/info',
        headers: {'Authorization': `Bearer ${token}`}
      }).then(({ data: loginInfo }) => {
        commit('saveUserInfo', { token, ...loginInfo });
        return { token, ...loginInfo };
      });
    });
  },

  refresh({commit, state, getters}){
    return ajax({
      url: '/system/auth/user/refresh',
      method: 'post',
      headers: {token: state.token}
    }).then(({ data }) => {
      commit('saveUserInfo', data);
      return data;
    });
  },

  autoLoginBySession({ commit, state }) {
    this.commit('app/init');
    this.commit('app/initRouteConfig');
    return state.userInfo ? Promise.resolve({replace: true}) : Promise.reject();
  },

  autoLoginByCookie({ commit, state }) {
    return Promise.reject();
  },

  autoLoginByCode({ commit, state, dispatch }, query) {
    if(query.code && query.state){
      return dispatch('autoLoginByThird', {type: query.state, code: query.code, tenant: query.tenant});
    }else{
      return Promise.reject();
    }
  },

  autoLoginByThird({commit, state}, loginInfo){
    return ajax({
      url: '/login/autoLoginByThird',
      method: 'post',
      data: loginInfo,
    }).then(({ data }) => {
      commit('saveUserInfo', data);
      return {replace: true};
    });
  },

  autoLoginByDingTalk({ commit, state, dispatch }, query) {
    if (dd && dd.env.platform !== "notInDingTalk") {
      return new Promise((resolve, reject) => {
        dd.ready(() => {
          dd.getAuthCode({
            corpId: query.corpId,
            success(result) {
              dispatch('autoLoginByThird', {type: 'dingTalk', code: result.code, tenant: query.tenant})
                .then(d => resolve(d))
                .catch(e => reject(e));
            },
            fail: function (err) {
              reject(err);
            }
          });
        });
      });
    } else {
      return Promise.reject();
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
