<template>
  <section class="app-main">
<!--    <router-view v-slot="{ Component, route }">-->
<!--      <transition mode="out-in" name="fade-transform">-->
<!--        <keep-alive :include="aliveTabs">-->
<!--          <component :is="Component" :key="route.fullPath" />-->
<!--        </keep-alive>-->
<!--      </transition>-->
<!--    </router-view>-->
<!--    <router-view v-slot="{ Component, route }">-->
<!--      <keep-alive :include="aliveTabs">-->
<!--        <component :is="Component" :key="route.fullPath" />-->
<!--      </keep-alive>-->
<!--    </router-view>-->
    <router-view />
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    aliveTabs() {
      const res = [];
      this.$store.state.app.menuTab.forEach(m => {
        if (!m.pageCode) return;
        res.push(m.pageCode);
      });
      return res;
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_constant.scss";
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .3s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
