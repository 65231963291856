// created by wangshuyi on 2022/9/16

'use strict';

//plugin

//component
import DolList from './display/dol-list';
import DolPagination from './display/dol-pagination.vue';
import DolTree from './display/dol-tree';
import DolCard from './display/dol-card';
import DolDict from './display/dol-dict.vue';
import DolImagePreview from './display/dol-image-preview.vue';
import DolSwitchLang from './common/dol-switch-lang';
import DolButton from './common/dol-button';
import DolDrawer from './feedback/dol-drawer';
import DolSelect from './form/dol-select';
import DolRadio from './form/dol-radio.vue';
import DolRadioCustomer from './form/dol-radio-customer.vue';
import DolCheckbox from './form/dol-checkbox.vue';
import DolTreeSelect from './form/dol-tree-select';
import DolUpload from './form/dol-upload';
import DolUploadImg from './form/dol-upload-img';
import DolUploadImgList from './form/dol-upload-img-list';
import DolImport from './form/dol-import.vue';
import DolDate from './form/dol-date';
import DolDateRange from './form/dol-date-range';
import DolTime from './form/dol-time.vue';
import DolTimePicker from './form/dol-time-picker.vue';

export {
  DolList,
  DolPagination,
  DolTree,
  DolCard,
  DolDict,
  DolImagePreview,
  DolSwitchLang,
  DolButton,
  DolDrawer,
  DolSelect,
  DolRadio,
  DolRadioCustomer,
  DolCheckbox,
  DolTreeSelect,
  DolUpload,
  DolUploadImg,
  DolUploadImgList,
  DolImport,
  DolDate,
  DolDateRange,
  DolTime,
  DolTimePicker,
};

export default {
  install(app) {

    app.component(DolList.name, DolList);
    app.component(DolPagination.name, DolPagination);
    app.component(DolTree.name, DolTree);
    app.component(DolCard.name, DolCard);
    app.component(DolDict.name, DolDict);
    app.component(DolImagePreview.name, DolImagePreview);
    app.component(DolSwitchLang.name, DolSwitchLang);
    app.component(DolButton.name, DolButton);
    app.component(DolDrawer.name, DolDrawer);
    app.component(DolSelect.name, DolSelect);
    app.component(DolRadio.name, DolRadio);
    app.component(DolRadioCustomer.name, DolRadioCustomer);
    app.component(DolCheckbox.name, DolCheckbox);
    app.component(DolTreeSelect.name, DolTreeSelect);
    app.component(DolUpload.name, DolUpload);
    app.component(DolUploadImg.name, DolUploadImg);
    app.component(DolUploadImgList.name, DolUploadImgList);
    app.component(DolImport.name, DolImport);
    app.component(DolDate.name, DolDate);
    app.component(DolDateRange.name, DolDateRange);
    app.component(DolTime.name, DolTime);
    app.component(DolTimePicker.name, DolTimePicker);
  }
};
