<template>
  <a-tree-select
    class="dol-tree-select"
    :allow-clear="allowClear"
    :value="innerValue"
    :tree-data="innerData"
    :placeholder="placeholder"
    :multiple="innerMultiple"
    :tree-checkable="innerCheckable"
    :fieldNames="{
      label: labelField,
      value: valueField,
      children: childrenField,
    }"
    @update:value="updateValue"
    @change="change"
  >

  </a-tree-select>
</template>

<script>
import requestMixins from '../common/requestMixins';
export default {
  name: "dol-tree-select",
  mixins: [requestMixins],
  props: {
    value: {},

    nodePath: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    treeCheckable: {type: Boolean, default: false},
    placeholder: {type: String, default: '--请选择--'},

    labelField: {type: String, default: 'name'},       //label
    valueField: {type: String, default: 'id'},          //value
    childrenField: {type: String, default: 'children'},

    method: {type: String, default: 'get'},
    pagination: {type: Boolean, default: false},

    allowClear: {type: Boolean, default: true},
  },
  emits: ['update:value', 'change'],
  computed: {
    innerValue() {
      let value;
      if(this.nodePath){
        value = this.value.length > 0 ? this.value[this.value.length - 1] : null;
      }else{
        value = this.value;
      }
      return value;
    },
    nodeMap() {
      return this.getNodeMap(this.innerData);
    },
    innerMultiple() {
      let flag;
      if(this.nodePath){
        flag = false;
      }else{
        flag = this.multiple;
      }
      return flag;
    },
    innerCheckable() {
      let flag;
      if(this.nodePath){
        flag = false;
      }else{
        flag = this.treeCheckable;
      }
      return flag;
    }
  },
  methods: {
    updateValue(value){
      let returnValue;
      if (value === undefined) {
        returnValue = null;
      } else {
        if(this.nodePath){
          returnValue = this.getNodeValuePath(value);
        }else{
          returnValue = value;
        }
      }
      this.$emit('update:value', returnValue);
    },
    change(value, label, extra){
      let returnValue;
      if (value === undefined) {
        returnValue = null;
      } else {
        if(this.nodePath){
          returnValue = this.getNodeValuePath(value);
        }else{
          returnValue = value;
        }
      }
      this.$emit('change', returnValue, label, extra);
    },

    getNodeMap(data) {
      let nodeMap = {};
      data.forEach(node => {
        nodeMap[node[this.valueField]] = node;
        if (node.children) {
          Object.assign(nodeMap, this.getNodeMap(node.children));
        }
      });
      return nodeMap;
    },
    getNodeValuePath(value) {
      let nodePath = [];
      let thisNode = this.nodeMap[value];
      if (thisNode.parent) {
        if (typeof thisNode.parent === 'string') {
          nodePath = nodePath.concat(this.getNodeValuePath(thisNode.parent));
        } else {
          nodePath = nodePath.concat(this.getNodeValuePath(thisNode.parent.id));
        }
      }
      nodePath.push(value);
      return nodePath;
    }
  },
  created() {
    if (this.source === 'server' && this.initDataFlag) {
      this.query();
    }
  },
};
</script>

<style lang="scss">
.dol-tree-select {

}
</style>
