<template>
  <div class="image-list-preview" v-if="imageList[0]">
    <template v-for="image in imageList">
      <span :style="{width, height, padding, border,borderRadius}" class="image-list-preview-thumb__block" @click="previewImage(image)">
        <img v-if="imageType === 'base64'" class="image-list-preview-thumb__image" :src="(base64PrefixFlag ? 'data:image/jpeg;base64,' : '')+image"/>
        <img v-else-if="/image/.test(image && image.fileType)" class="image-list-preview-thumb__image" :src="$Dolphin.path.publicPath + image.filePath"/>
        <template v-else>
          <dol-icon class="image-list-preview-thumb__file-icon" type="file" />
          <dol-icon class="image-list-preview-thumb__file-icon image-list-preview-thumb__download" type="download"/>
          <div class="image-list-preview-thumb__file-name" >{{image && image.name}}</div>
        </template>
      </span>
    </template>
    <a-modal :visible="previewVisible" :wrap-class-name="grayModalBackground ? 'gray-preview-image-modal' : ''" :footer="null" width="800px" @cancel="handleCancel">
      <img class="image-list-preview-thumb__preview" v-if="previewImageUrl" :src="previewImageUrl"/>
    </a-modal>
  </div>
</template>

<script>
  export default {
    name: "dol-image-preview",
    props:{
      imageList: {type: Array, default: () => []},
      imageType: {type: String, default: 'file'}, //file | base64
      base64PrefixFlag: {type: Boolean, default: true},
      width: {type: String, default: '100px'},
      height: {type: String, default: '100px'},
      padding: {type: String, default: '5px'},
      borderRadius: {type: String, default: '10px'},
      border: {type: String, default: '1px solid #bbbbbb'},

      grayModalBackground: {type: Boolean, default: false},
    },
    data(){
      return {
        previewVisible: false,
        previewImageUrl: '',
      }
    },
    computed:{},
    methods:{
      handleCancel() {
        this.previewVisible = false;
        this.previewImageUrl = '';
      },
      previewImage(image){
        if(this.imageType === 'base64'){
          this.previewImageUrl = (this.base64PrefixFlag ? 'data:image/jpeg;base64,' : '')+image;
          this.previewVisible = true;
        }else if(/image/.test(image && image.fileType)){
          this.previewImageUrl = this.$Dolphin.path.publicPath + image.filePath;
          this.previewVisible = true;
        }else{
          this.$Dolphin.download(image && image.name, this.$Dolphin.path.publicPath + image.filePath)
        }
      }
    }
  }
</script>

<style lang="scss">
  .image-list-preview{
    display: flex;

    .image-list-preview-thumb__block{
      border: 1px solid #bbbbbb;
      padding: 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      text-align: center;
      flex-direction: column;

      + .image-list-preview-thumb__block{
        margin-left: 10px;
      }

      .image-list-preview-thumb__image{
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }

      .image-list-preview-thumb__file-icon{
        font-size: 30px;
      }

      .image-list-preview-thumb__file-icon.image-list-preview-thumb__download{
        display: none;
      }
      &:hover{
        .image-list-preview-thumb__file-icon{
          display: none;
        }
        .image-list-preview-thumb__file-icon.image-list-preview-thumb__download{
          cursor:pointer;
          display: block;
        }
      }
      .image-list-preview-thumb__file-name{
        font-size: 12px;
        overflow: hidden;
        width: 100%;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  .image-list-preview-thumb__preview{
    max-width: 100%;
  }

  .gray-preview-image-modal{
    .ant-modal-body{
      background: #bbbbbb;
      text-align: center;
    }
  }
</style>
