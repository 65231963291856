// created by wangshuyi on 2022/9/19

'use strict';

import { mapGetters } from 'vuex';

export default {
  data() {
    this.query = this.$Dolphin.debounce(this.query, 500);
    return {
      name: 'page',
      title: '页面',
      url: {},

      queryForm: {},
      queryProperties: {},
      selectedRowKeys: [],
      selectedRows: [],

      editVisible: false,
      editForm: {},
      editObjectFields: [],
      editObjectArrayFields: [],
      editArrayFields: [],
      asEdit: false,
      saving: false,
      validateRules: {},

      detailVisible: false,
      detailForm: {},

      //用于实现查询条件的显示/隐藏
      queryField: [],
      queryFormFieldArea: []
    };
  },
  computed: {
    ...mapGetters(['token', 'userInfo', 'tenantInfo']),
    queryFormFieldOptions() {
      let options = [];
      options.push({ code: 'name', text: '名称', fields: ['name'] });
      return options;
    },
  },
  methods: {
    query() {
      if (this.$refs.list) {
        this.$refs.list.query();
      }
    },
    load() {
      if(this.$refs.list){
        this.$refs.list.load();
      }
    },
    clearValidate() {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
    },
    beforeAdd(data) {
    },
    add() {
      let data = {};
      this.editArrayFields.forEach(field => {
        data[field] = [];
      });
      this.editObjectArrayFields.forEach(field => {
        data[field] = [];
      });
      this.editForm = this.beforeAdd(data) || data;

      this.asEdit = false;
      this.clearValidate();
      this.editVisible = true;
    },

    beforeEdit(data) {
    },
    edit(data) {
      let formData = Object.assign({}, data);
      this.editObjectFields.forEach(field => {
        formData[field] = formData[field] && formData[field].id;
      });
      this.editObjectArrayFields.forEach(field => {
        let ids = [];
        formData[field] && formData[field].forEach(item => ids.push(item.id));
        formData[field] = ids;
      });
      this.editArrayFields.forEach(field => {
        formData[field] = formData[field] ? formData[field].concat() : [];
        formData[field].forEach((item, index) => {
          if (typeof item === 'object') {
            formData[field][index] = { ...item };
          }
        });
      });

      this.editForm = this.beforeEdit(formData) || formData;
      this.asEdit = true;
      this.clearValidate();
      this.editVisible = true;
    },

    beforeSave(data) {
    },
    validate() {
      if (this.$refs.editForm) {
        return this.$refs.editForm.validate();
      } else {
        return Promise.resolve();
      }
    },
    save() {
      return this.validate().then(() => {
        let submitData = { ...this.editForm };
        this.beforeSave(submitData);
        this.saving = true;
        this.$ajax({
          url: this.url.save,
          method: 'post',
          data: submitData,
          pathData: { id: this.asEdit ? this.editForm.id : '' },
        }).then(reData => {
          this.editVisible = false;
          this.$message.info(reData.message);
          this.afterSave(reData);
          this.query();
        }).catch(e => {
        }).finally(() => {
          this.saving = false;
        });
      });
    },
    afterSave(reData) {
    },

    removeAll() {
      let message, condition;
      Promise.resolve().then(() => {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
          message = `将删除${this.selectedRowKeys.length}条已选中记录, 是否继续?`;
          condition = { id_in: this.selectedRowKeys };
          return true;
        } else {
          return this.$ajax({
            url: this.url.count,
            method: 'post',
            data: this.queryForm,
          }).then(({ data }) => {
            if (data > 0) {
              message = `此查询条件同找到${data}条记录，将全部删除, 是否继续?`;
              condition = this.queryForm;
            } else {
              this.$message.info('未匹配到相关数据');
              throw null;
            }
          });
        }
      }).then(() => {
        this.$confirm({
          content: message,
          onOk: () => {
            return this.$ajax({
              url: this.url.remove,
              method: 'post',
              data: condition
            }).then(({ data }) => {
              this.$message.info(`成功删除${data}条记录`);
              this.selectedRowKeys = [];
              this.selectedRows = [];
              this.query();
            });
          },
        });
      }).catch(() => {});
    },
    removeOne(record) {
      let message = `此操作将永久删除, 是否继续?`;
      this.$confirm({
        content: message,
        onOk: () => {
          return this.$ajax({
            url: this.url.removeOne,
            pathData: { id: record.id },
          }).then(reData => {
            this.$message.info(reData.message);
            this.query();
          });
        },
        onCancel() {
        },
      });
    },
    disable(record) {
      let message = `确定禁用当前数据?`;
      this.$confirm({
        content: message,
        onOk: () => {
          return this.$ajax({
            url: this.url.setStatus,
            method: 'post',
            data: { id: record.id, status: 'disabled' },
          }).then(reData => {
            this.$message.info(reData.message);
            this.load();
          });
        },
        onCancel() {
        },
      });
    },
    enable(record) {
      let message = `启用当前数据?`;
      this.$confirm({
        content: message,
        onOk: () => {
          return this.$ajax({
            url: this.url.setStatus,
            method: 'post',
            data: { id: record.id, status: 'enable' },
          }).then(reData => {
            this.$message.info(reData.message);
            this.load();
          });
        },
        onCancel() {
        },
      });
    },

    detail(data) {
      this.detailForm = data;
      this.detailVisible = true;
    },
    downloadTemplate() {
      this.$Dolphin.download(`${this.title}导入模板.xlsx`, this.$Dolphin.path.staticPath + `/importTemplate/${this.title}导入模板.xlsx`);
    },
    exportData() {
      this.$ajax({
        url: this.url.exportData,
        method: 'post',
        data: this.queryForm,
        loading: true,
      }).then(({ data }) => {
        this.$Dolphin.download(data.fileName, this.$Dolphin.path.staticPath + data.filePath);
      });
    },
    pickConditionField(value) {
      value && localStorage.setItem(this.pageName + 'ConditionField', value.join(','));

      let removeCondition = this.queryFormFieldArea.concat().filter(field => !value.includes(field.code));
      let newCondition = this.queryForm;
      removeCondition.forEach(field => {
        if (field.fields) {
          field.fields.forEach(field => delete newCondition[field]);
        } else if (field.computedField) {
          this[field.computedField] = field.defaultValue;
        } else {
          delete newCondition[field.code];
        }
      });
      this.queryForm = newCondition;
      // 主动触发查询，在这触发是否会过于频繁
      // this.query();
    }
  },
  created() {
    let incomeConditionField = localStorage.getItem(this.name + 'ConditionField');
    this.queryField = incomeConditionField ? incomeConditionField.split(',') : [];
  }
};
