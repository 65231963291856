<template>
  <dol-select
    class="select-time-picker"
    :value="value"
    source="local"
    :default-data="timeOption"
    :empty-option="false"
    @update:value="updateValue"
    @change="change"
  >
    <template #suffixIcon><dol-icon type="ClockCircleOutlined" /></template>
  </dol-select>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: "dol-time-picker",
  props: {
    value: {type: String},
    startTime: {type: Object, default: () => dayjs().startOf('day')},
    endTime: {type: Object, default: () => dayjs().endOf('day')},
    step: {type: Number, default: 15},
  },
  emits: ['update:value', 'change'],
  computed: {
    timeOption(){
      const startMinute = this.startTime.format('mm');
      const startIndex = Math.ceil(startMinute / this.step);
      let startTime = this.$dayjs(this.startTime.format('YYYY-MM-DD HH:00:00'));
      startTime = startTime.add(this.step * startIndex, 'minute');

      const endMinute = this.endTime.format('mm');
      const endIndex = Math.floor(endMinute / this.step);
      let endTime = this.$dayjs(this.endTime.format('YYYY-MM-DD HH:00:00'));
      endTime = endTime.add(this.step * endIndex, 'minute');

      const options = [];
      while(startTime.isSameOrBefore(endTime)){
        const time = startTime.format('HH:mm');
        options.push({code: time, text: time, text_en: time});
        startTime = startTime.add(this.step, 'minute');
      }

      return options;
    }
  },
  methods: {
    updateValue(v){
      this.$emit('update:value', v);
    },
    change(v, o){
      this.$emit('change', v, o);
    }
  }
};
</script>

<style lang="scss">
.select-time-picker{
  min-width: 130px
}

</style>
