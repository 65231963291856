<template>
  <a-checkbox-group :value="value" @update:value="change">
    <a-checkbox v-for="o in options" :optionData="o" :value="o[valueFieldFinal]" :key="o[keyFieldFinal]">
      <slot name="option" :option="o">
        <div v-html="showLabel(o[labelFieldFinal])"></div>
      </slot>
    </a-checkbox>
  </a-checkbox-group>
</template>

<script>
import requestMixins from '../common/requestMixins';

export default {
  name: "dol-checkbox",
  mixins: [requestMixins],
  props: {
    value: {},
    keyField: { type: String },        //key  修改了入参的名字以便使用 keyField不传的时候默认会使用valueField
    labelField: { type: String },       //label
    valueField: { type: String },          //value

    source: { type: String, default: 'dict' },            //数据源，server | local | dict
  },
  emits: ['update:value', 'change'],
  data() {
    this.search = this.$Dolphin.debounce(this.search, 500);
    return {
      searchValue: '',
    };
  },
  computed: {
    keyFieldFinal() {
      if (this.keyField) return this.keyField;
      return this.valueFieldFinal;
    },
    labelFieldFinal() {
      if (this.labelField) return this.labelField;
      return this.source === 'server' ? 'name' : 'text';
    },
    valueFieldFinal() {
      if (this.valueField) return this.valueField;
      return this.source === 'server' ? 'id' : 'code';
    },
    options() {
      if (this.source === 'server' || this.searchValue === '') {
        return this.innerData;
      } else {
        return this.innerData.filter(item => {
          const matchLabel = new RegExp(this.searchValue, 'i').test(item[this.labelFieldFinal]);
          const matchValue = this.value instanceof Array ? this.value.find(item[this.valueFieldFinal]) : item[this.valueFieldFinal] === this.value;
          return matchLabel || matchValue;
        });
      }
    }
  },
  watch: {
    url() {
      this.load();
    },
    dictCode() {
      this.load();
    },
  },
  methods: {
    change(value) {
      this.searchValue = '';
      this.$emit('update:value', value);
      let thisOption = this.innerData.find(o => o[this.valueFieldFinal] === value);
      this.$emit('change', value, thisOption);
    },
    search(val) {
      this.searchValue = val;
    },

    showLabel(label) {
      if (!this.searchValue) {
        return label;
      }

      const before = '<span class="color-primary">';
      const after = '</span>';
      return label.replace(new RegExp(`(${this.searchValue})`, 'ig'), before + '$1' + after);
    },
  },
  created() {
    this.query();
  },
};
</script>

<style lang="scss">
.dol-select {

}
</style>
