<template>
  <div class="portal-layout">
    <portal-header class="portal-layout-header" />
    <div class="portal-layout-body">
      <app-main />
    </div>
  </div>
</template>

<script>
import appMain from '@/layout/app-main.vue';
import portalHeader from '@/layout/portalLayout/portal-header.vue';

export default {
  name: 'portal-layout.vue',
  components: {appMain, portalHeader},
  data () {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss">
.portal-layout{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #F0F2F5;

  .portal-layout-header{
    background: #FFFFFF;
  }
  .portal-layout-body{
    flex: 1;
    overflow: auto;
    padding: 20px;
    .app-main{
      height: 100%;
    }
  }
}
</style>
