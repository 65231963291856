<template>
  <div class="dishes_output" :style="{width: width}">
    <div class="dishes_output_title">
      <svg-icon name="dishes" />
      <span class="dot_text">实时菜品产出</span>
      <div class="dot_time">{{ time }}</div>
    </div>
    <div class="dishes_output_body">
      <div class="dob_chart">
        <chart-line
          :x-axis-tick="xAxisTick" :x-axis-line="xAxisLine" :item-style="itemStyle" :show-date-label="false" :x-text-style="xTextStyle"
          :axis-label-show="false" :axis-tick-show="false" :y-split-line="splitLine" :interval="interval" :max-inside="false"
          :category="['0', '4', '8', '12', '16', '20']" :legend="['0', '4', '8', '12', '16', '20']"
          type="bar" :data="[data]" height="200px" barWidth="40px" />
      </div>
      <div class="dob_data">
        <div class="dob_data_item">
          <div class="dob_data_item_label">最高产出</div>
          <div class="dob_data_item_value">{{ maxOutput }} <div class="unit">/h</div></div>
        </div>
        <div class="dob_data_item">
          <div class="dob_data_item_label">最低产出</div>
          <div class="dob_data_item_value">{{ minOutput }} <div class="unit">/h</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 实时菜品产出
import SvgIcon from '@/components/base/svgIcon.vue';
import ChartLine from '@/components/echarts/chart-line.vue';

export default {
  name: 'realTimeDishesOutput',
  components: { ChartLine, SvgIcon },
  props: {
    width: { type: String, default: '100%' },
    // 最高产出
    maxOutput: {
      type: Number,
      default: 999
    },
    // 最低产出
    minOutput: {
      type: Number,
      default: 99
    },
    // echarts 数据
    data: {
      type: Array,
      default: () => {
        return [30, 50,100, 120, 80, 40]
      }
    }
  },
  computed: {
    // 当前时间
    time() {
      return this.$dayjs().format('YYYY/MM/DD HH:mm');
    },
    interval () {
      // 最大数值index
      const maxIndex = Math.max(...this.data);
      return maxIndex/3 > 10 ? maxIndex/3 : maxIndex/2;
    },
    // 分割线样式
    splitLine() {
      const colors = []
      let max = Math.max(...this.data);
      if(max/3 > 10){
        colors[0] = colors[3] = '#0F0F19';
        colors[1] = colors[2] = 'rgba(237,236,236,0.1)';
      } else {
        colors[0] = colors[2] = '#0F0F19';
        colors[1] = 'rgba(237,236,236,0.1)';
      }

      return {
        lineStyle: {
          show: true,
          color: colors, // 刻度线颜色
          width: 1, // 刻度线宽度
          type: 'dashed', // 刻度线类型：solid, dashed, dotted
        },
        max: 'dataMax',
      }
    },
    xTextStyle() {
      return {
        color: 'rgba(255,255,255,0.3)'
      }
    },
    xAxisTick() {
      return {
        show: false,
      }
    },
    xAxisLine() {
      return {
        show: false,
      }
    },
    itemStyle() {
      return {
        normal: {
          barBorderRadius: 12,
          color: function(params) {
            const colorList = [
              '#777A96',
              '#777A96',
              '#777A96',
              '#777A96',
              'rgba(119,122,150,0.2)',
              'rgba(119,122,150,0.2)'
            ]
            return colorList[params.dataIndex];
          }
        }
      }
    }
  },
  mounted () {
    console.log(this.interval);
  }
};
</script>

<style scoped lang="scss">
.dishes_output_bg {

}
.dishes_output{
  min-height: 280px;
  border-radius: 24px;
  padding: 24px;
  //background-image: linear-gradient(180deg, rgba(59, 59, 67, 1), rgba(18, 18, 26, 1));
  background: #0F0F19;

  .dishes_output_title{
    display: flex;
    align-items: center;

    .dot_text {
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;
      margin-left: 12px;
    }
    .dot_time {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.4);
      height: 24px;
      display: flex;
      align-items: flex-end;
      margin-left: 8px;
    }
  }
  .dishes_output_body {
    display: flex;
    gap: 36px;
    margin-top: 8px;
    .dob_chart{
      flex: 1;
    }
    .dob_data{
      min-width: 100px;

      .dob_data_item{

        + .dob_data_item{
          margin-top: 32px;
        }
        .dob_data_item_label{
          font-size: 13px;
          color: rgba(255, 255, 255, 0.2);
          line-height: 15px;
        }
        .dob_data_item_value{
          margin-top: 12px;
          font-weight: 600;
          font-size: 32px;
          color: #FFFFFF;
          line-height: 38px;

          .unit{
            display: inline-flex;
            height: 32px;
            font-size: 21px;
            align-items: flex-end;
            color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}
</style>
