import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['userInfo']),
    userType () {
      return this.userInfo.type;
    },
    isTenantStaff() {
      return this.userType === 'tenantStaff';
    },
    iconPrefix() {
      return this.isTenantStaff ? '' : 'tenant_';
    },
  }
}
