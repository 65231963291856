<template>
  <div>
    <a-upload :action="action"
              name="file"
              :headers="{Authorization: `Bearer ${token}`}"
              listType="picture-card"
              :fileList="fileList"
              :data="data"
              multiple
              @preview="handlePreview"
              @change="handleChange">
      <div v-if="fileList.length < max">
        <dol-icon type="PlusOutlined"/>
        <div class="ant-upload-text">上传图片</div>
      </div>
    </a-upload>
    <a-modal v-model:open="previewVisible" :footer="null" >
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'dol-upload-img-list',
  props: {
    value: {type: Array},
    type: {type: String, default: ''},            //文件类型，可为空
    tenant: {type: String},            //所属租户，可为空
    url: {type: String, default: '/system/tool/file/save'},            //文件类型，可为空
    max: {type: Number, default: 5},             //最大上传数
    data: {type: Object, default: () => {return {}}},            //数据绑定
  },
  data(){
    return {
      previewVisible: false,
      previewImage: '',
      fileList: this.value,
      fileListInside: [],
    }
  },
  computed:{
    ...mapGetters(['token']),
    action(){
      let action = this.$Dolphin.path.apiPath + this.url;
      const params = [];
      if(this.type) params.push(`type=${this.type}`);
      if(this.tenant) params.push(`tenant=${this.tenant}`);
      return `${action}?${params.join('&')}`;
    }
  },
    watch: {
      value(val) {
        this.updateData(val);
      }
    },
  methods: {
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl || file.filePath;
      this.previewVisible = true
    },
    // 图片list有更新时触发（新增、删除、更改）
    handleChange({fileList}) {
      this.fileList = fileList;
      let uploading = fileList.find(file => file.status !== 'done');
      if(!uploading){
        this.$emit('update:value', fileList.map(item => {
          if(item.response){
            return item.response.data.filePath;
          }else{
            return item.dataSource;
          }
        }));
      }
    },
    //数据初始化
    updateData(val) {
      this.fileList = [];
      val.forEach((img, i) => {
        this.fileList.push({
          name: img.name,
          uid: img.uid || img,
          fileId: img.fileId || img,
          status: img.status || 'done',
          url: img.url || this.$Dolphin.path.publicPath + img,
          dataSource: val[i],
        });
      });
    },
  },
  created(){
    this.updateData(this.value);
  }
}
</script>
<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
