<template>
  <a-upload
    name="file"
    :headers="{Authorization: `Bearer ${token}`}"
    :action="action"
    :multiple="multiple"
    :show-upload-list="showUploadList"
    :data="data"
    :before-upload="beforeUpload"
    @change="change"
    ref="upload">
    <slot :fileList="fileList">
      <a-button>
        <dol-icon type="UploadOutlined" /> Click to Upload
      </a-button>
    </slot>
  </a-upload>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'dol-upload',
  props: {
    type: { type: String, default: '' },            //文件类型，可为空
    url: { type: String, default: '/system/tool/file/save' },            //文件类型，可为空
    multiple: { type: Boolean, default: false },            //是否多选
    showUploadList: { type: Boolean, default: false },            //列表显示方式
    data: { type: Object, default: () => {return {}} },            //数据绑定
    uploadWithPick: { type: Boolean, default: true },            //选择文件后立即上传
  },
  data () {
    return {
      fileList: [],
    }
  },
  computed: {
    ...mapGetters(['token']),
    action () {
      let action = this.$Dolphin.path.apiPath + this.url;
      if (this.type) {
        action += `?type=${this.type}`;
      }
      return action;
    }
  },
  methods: {
    beforeUpload (file, fileList) {
      if (this.multiple) {
        this.fileList.push(file);
      } else {
        this.fileList = [file];
      }
      if (this.uploadWithPick) {
        this.$emit('before-upload');
        this.$store.commit('app/setLoading', true);
        return true;
      } else {
        return false;
      }
    },
    change (data) {
      this.$emit('uploading');
      if (data.file.status === 'done') {
        this.$emit('finished', data, data.file.response);
        this.$store.commit('app/setLoading', false);
      }
    },
    upload () {
      let param = new FormData();

      //通过append向form对象添加数据
      for (let key in this.data) {
        param.append(key, this.data[key]);
      }

      //添加文件
        param.append("file", this.fileList[0]);

      this.$ajax({
        url: this.url,
        data: param,
        headers: { "Content-Type": "multipart/form-data", token: this.token },
        method: 'post',
        loading: true,
      }).then(result => {
        // this.$message.info(result.message);
        this.$emit('finished', this.fileList, result);
      })
    }
  },
  watch: {},
  created () {
  }
}
</script>
