<template>
  <a-upload
    name="file"
    :headers="{Authorization: `Bearer ${token}`}"
    listType="picture-card"
    :show-upload-list="false"
    :action="action"
    :data="data"
    @change="change"
    ref="upload">
    <slot v-if="imageUrl" name="uploadImage" :imageUrl="imageUrl">
      <div class="upload-img__box">
        <dol-icon class="upload-img__delete-icon" type="CloseCircleOutlined" @click.prevent.stop="remove" />
        <img :src="imageUrl" class="upload-img__image" />
      </div>
    </slot>
    <div v-else>
      <slot>
        <dol-icon :type="loading ? 'LoadingOutlined' : 'PlusOutlined'" />
        <div class="ant-upload-text">
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </div>
      </slot>
    </div>
  </a-upload>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'dol-upload-img',
  props: {
    value: { type: String },
    placeholder: { type: String, default: '上传图片' },
    type: { type: String, default: '' },            //文件类型，可为空
    tenant: { type: String, default: '' },            //所属租户，可为空
    url: {type: String, default: '/system/tool/file/save'},            //文件类型，可为空
    data: { type: Object, default: () => ({}) },            //数据绑定
    uploadWithPick: { type: Boolean, default: true },            //选择文件后立即上传

  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['token']),
    action() {
      let action = this.$Dolphin.path.apiPath + this.url;
      const params = [];
      if(this.type) params.push(`type=${this.type}`);
      if(this.tenant) params.push(`tenant=${this.tenant}`);
      return `${action}?${params.join('&')}`;
    },
    imageUrl: {
      get() {
        if (this.value) {
          return this.$Dolphin.path.publicPath + this.value;
        } else {
          return '';
        }
      },
      set() {
      },
    },
  },
  methods: {
    change(data) {
      if (data.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (data.file.status === 'done') {
        if (data.file.response.success) {
          this.imageUrl = this.$Dolphin.path.publicPath + data.file.response.data.filePath;
          this.$emit('update:value', data.file.response.data.filePath);
          this.$emit('finished', data.file.response.data);
        }
        this.loading = false;
      }
    },
    remove() {
      this.$emit('update:value', null);
      this.$emit('remove');
    },
  },
};
</script>
<style lang="scss">
.upload-img__box {
  width: 102px;
  height: 102px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-img__delete-icon {
    font-size: 20px;
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .upload-img__image {
    //max-width: 102px;
    //max-height: 102px;
    max-width: 100%;
    max-height: 100%;
  }
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
