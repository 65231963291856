<template>
  <a-card class="dol-card" v-if="cardFlag" :title="titleFlag && title" :size="size">
    <template #extra>
      <slot v-if="titleFlag" name="extra"/>
    </template>
    <div class="card-query-form" v-if="queryFormFlag"><slot name="queryForm" /></div>
    <slot></slot>
  </a-card>
  <div class="dol-card" v-else>
    <div class="card-query-form"><slot name="queryForm" /></div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'dol-card',
    props: {
      title: {type: String, default: '面板'},
      titleFlag: {type: Boolean, default: true},
      cardFlag: {type: Boolean, default: true},
      queryFormFlag: { type: Boolean, default: true },
      size: {type: String},
    },
    data(){
      return {};
    },
    computed: {},
    watch: {},
    methods: {},

    created() {},
  }
</script>

<style lang="scss">
.dol-card{
  .card-query-form{
    margin-bottom: 7px;
  }
}
</style>
