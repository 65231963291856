<template>
  <dol-card
    class="dol-pagination"
    :class="cardClass"
    :card-flag="cardFlag"
    :query-form-flag="queryFormFlag"
    :size="cardSize"
    :title="title"
    :title-flag="titleFlag"
  >
    <slot :rows="innerData" :total="innerTotal">{{innerData}}</slot>
    <template v-if="queryFormFlag" #queryForm>
      <slot name="queryForm" />
    </template>
    <div class="pagination-row" v-if="pagination && innerTotal">
      <a-pagination
        v-model:current="pageNumber"
        :total="innerTotal"
        v-model:page-size="pageSize"
        :show-size-changer="paginationShowSizeChanger"
        :page-size-options="pageSizeOption"
        :show-quick-jumper="paginationShowQuickJumper"
        :show-total="showTotal"
        @change="onPageChange"
      />
    </div>

    <div class="dol-pagination-loading" v-if="loadingFlag">
      <dol-icon type="LoadingOutlined" />
    </div>
  </dol-card>
</template>

<script>
import requestMixins from '../common/requestMixins';

export default {
  name: 'dol-pagination',
  mixins: [requestMixins],
  props: {
    cardFlag: { type: Boolean, default: true },
    cardClass: { type: String },
    cardSize: { type: String, default: 'small' },
    title: { type: String, default: '列表' },
    titleFlag: { type: Boolean, default: true },
    queryFormFlag: { type: Boolean, default: true },

    size: { type: String, default: 'small' },
    rowKey: { type: String, default: 'id'},
  },
  computed:{
    showTotal(){
      if (this.paginationShowTotal) {
        return (total, range) => `第 ${range[0]}-${range[1]} 条，共 ${total} 条`;
      }else{
        return undefined;
      }
    }
  },
  methods:{
    onPageChange(pageNumber, pageSize) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;

      if (this.paginationType === 'server') {
        this.load();
      }
    },

  },
  created(){
    if (this.source === 'server' && this.initDataFlag) {
      this.query();
    }
  },
}
</script>

<style lang="scss">
.dol-pagination{
  position: relative;
  .pagination-row{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .dol-pagination-loading{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 30px;
  }
}
</style>
