import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

const app = createApp(App);
app.use(store);
app.use(router);

//时间
import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/relativeTime'))
dayjs.extend(require('dayjs/plugin/localeData'))
dayjs.extend(require('dayjs/plugin/isSameOrBefore'))
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
dayjs.locale('zh-cn');
app.config.globalProperties.$dayjs = dayjs;

//Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
app.use(Antd);

//echarts
import * as echarts from 'echarts'
app.config.globalProperties.$echarts = echarts;

//国际化
import i18n from './i18n/index';
app.use(i18n);

//Dolphin
import Dolphin from 'dolphin-framework-vue';
app.use(Dolphin);

import customerComponent from '@/components/custmor';
app.use(customerComponent);

//Dolphin ant design
import DolphinAntDesign from './components/dolphin-ant-design';
app.use(DolphinAntDesign);
import './assets/scss/common.scss';

//common js
import config from './components/DolphinConfig';
app.use(config);

import mixin from './mixin';
app.mixin(mixin);

app.mount('#app');
