// created by wangshuyi on 2022/9/1

'use strict';
import dayjs from 'dayjs';
import { ajax } from "dolphin-framework-vue";
import i18n from '@/i18n';

const state = {
  localStorageName: process.env.VUE_APP_COOKIE_NAME,
  checkLang: false,
  terminal: 'manager',
  langType: [],
};

const mutations = {
  changeLang(state, lang) {
    localStorage.setItem(state.localStorageName + "-Lang", lang);
    i18n.global.locale = lang;
    lang === 'zh' ? dayjs.locale('zh-cn') : dayjs.locale('en');
  },
  setDefaultLang(state) {
    const localLang = localStorage.getItem(state.localStorageName + '-Lang');
    if (localLang) {
      this.commit('lang/changeLang', localLang);
      return;
    }

    const navigatorLang = navigator.language;
    const matchLangType = state.langType.find(lang => lang.id === navigatorLang || new RegExp(navigatorLang).test(lang.include));
    if (matchLangType) {
      this.commit('lang/changeLang', matchLangType.id);
      return;
    }

    const defaultLangType = state.langType.find(lang => lang.defaultLang);
    if (defaultLangType) {
      this.commit('lang/changeLang', defaultLangType.id);
      return;
    }

    this.commit('lang/changeLang', 'zh');
  },
};

const actions = {
  checkLang({ state, dispatch, commit }) {
    if (state.checkLang) {
      return Promise.resolve();
    }

    state.checkLang = true;

    const localLastTime = localStorage.getItem(state.localStorageName + '-LangTime');
    const langStr = localStorage.getItem(state.localStorageName + '-LangConfig');
    if (localLastTime && localLastTime !== 'undefined' && langStr) {
      try {
        state.langType = JSON.parse(localStorage.getItem(state.localStorageName + '-LangType')) || [];
      } catch (e) {
      }
      commit('setDefaultLang');
      const langMap = JSON.parse(langStr);
      for (const key in langMap) {
        i18n.global.mergeLocaleMessage(key, langMap[key])
      }
      ajax({
        url: '/system/lang/langConfig/getLastUpdateTime',
        params: { terminal: state.terminal }
      }).then(({ data }) => {
        if (dayjs(localLastTime).isBefore(dayjs(data))) {
          return dispatch('updateLangConfig');
        }
      });
      return Promise.resolve();
    } else {
      return dispatch('updateLangConfig');
    }
  },
  updateLangConfig({ state, commit }) {
    return ajax({
      url: '/system/lang/langConfig/getLangConfig',
      params: { terminal: state.terminal }
    }).then(({ data }) => {
      const { langMap, updateTime, defaultLang, langType } = data;
      localStorage.setItem(state.localStorageName + '-LangTime', updateTime);
      localStorage.setItem(state.localStorageName + '-LangConfig', JSON.stringify(langMap));
      localStorage.setItem(state.localStorageName + '-LangType', JSON.stringify(langType));
      state.langType = langType;
      commit('setDefaultLang');

      for (const key in langMap) {
        i18n.global.mergeLocaleMessage(key, langMap[key])
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
