import { createStore } from 'vuex'

import appState from './appState';
import userState from './userState';
import langState from './langState';

export default createStore({
  state: {
  },
  getters: {
    userInfo: state => state.user.userInfo,
    tenantInfo: state => state.user.tenantInfo,
    token: state => state.user.token,
    menuList: state => state.user.menuList,
    langType: state => state.lang.langType,
    prompt: state => state.app.prompt,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app: appState,
    user: userState,
    lang: langState,
  }
})
