<template>
  <a-dropdown>
    <div class="dol-switch-lang">
      <div class="locale-info">
        <dol-icon type="global-outlined" />
        <span class="locale-info-text">{{ langMap[$i18n.locale] || $i18n.locale }}</span>
        <dol-icon type="DownOutlined" />
      </div>
    </div>
    <template #overlay>
      <a-menu @click="changeLang">
        <a-menu-item v-for="lang in langType" :key="lang.id" class="text-align-center">{{ lang.label }}</a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: "dol-switch-lang",
  computed: {
    ...mapGetters(['langType']),
    langMap(){
      let map = {};
      this.langType.forEach(lang => map[lang.id] = lang.label);
      return map;
    },
  },
  methods: {
    changeLang({key}){
      store.commit('lang/changeLang', key);
    }
  },
};
</script>

<style lang="scss">
.dol-switch-lang {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  .locale-info{
    line-height: 20px;
    padding: 4px;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: center;
    .locale-info-text{
      color: #333333;
      margin-right: 6px;
      margin-left: 6px;
    }
    .dol-icon-global-outlined{
    }
    .dol-icon-down-outlined{
      font-size: 10px;
    }
  }
}
</style>
