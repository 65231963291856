<template>
  <div class="header-extra-search" :class="searchClass" @click="onSearchFocus">
    <img src="../../../public/static/icon/search.svg">
<!--    <a-input class="header-extra-search-input" placeholder="请输入..."-->
<!--             :bordered="false" :maxlength="20" v-model="value"/>-->
    <dol-select
      class="layout-menu-search header-extra-search-input"
      v-model:value="value"
      source="local"
      :default-data="menuList"
      :placeholder="$t('菜单搜索')"
      :dropdown-match-select-width="false"
      :empty-option="false"
      keyField="id"
      :labelField="$i18n.locale === 'zh' ? 'fullName' : 'fullNameEn'"
      valueField="id"
      ref-name="layoutMenuSearchSelect"
      @change="change"
      @blur="onSearchBlue"
      ref="layoutMenuSearch"
    />
  </div>
</template>

<script>
export default {
  name: "the-menu-search",
  data() {
    return {
      value: undefined,
      menuSearch: [],
      searchClass: '',
    };
  },
  computed: {
    menuList() {
      return this.$store.state.user.pageList.filter(item => item.type === 'menu' && item.component);
    },
  },
  methods: {
    onSearchFocus () {
      this.searchClass = 'search_focus';
      // 设置焦点
      this.$nextTick(() => {
        this.$refs.layoutMenuSearch.$refs.layoutMenuSearchSelect.focus();
      });
    },
    onSearchBlue () {
      this.searchClass = '';
    },
    change(menuId, menuInfo) {
      if (menuInfo && menuInfo.fullPath) this.$router.push(menuInfo.fullPath);
      this.$nextTick(() => {
        this.value = undefined;
      });
    },
  },
};
</script>

<style lang="scss">
$width: 42px;

.header-extra-search {
  width: $width;
  height: $width;
  margin-right: 32px;
  border-radius: calc($width / 2);
  overflow: hidden;
  background: #0F0F19;
  display: flex;
  align-items: center;
  cursor: pointer;
  // 动画时常1s
  transition: width 0.5s;

  .header-extra-search-input {
    display: none;
  }

  &:hover {
    // 磨砂玻璃
    //filter: blur(0.1px);
    background: #1F1F2A;
  }

  &.search_focus {
    width: 240px;

   .ant-select:not(.ant-select-customize-input) .ant-select-selector{
     background: unset;
     border: unset;
     height: $width;
    }
    .header-extra-search-input {
      display: unset;
      width: calc(100% - 68px);
      margin-left: 8px;

      .ant-select-selection-search{
        display: flex;
        align-items: center;

        .ant-select-selection-search-input{
          display: flex;
          align-items: center;
          line-height: calc($width - 8px);
          font-size: 16px;
        }
      }
      .ant-select-selection-placeholder {
        line-height: $width;
        font-size: 16px;
      }
    }
  }

  img {
    width: calc($width / 2);
    height: calc($width / 2);
    margin-left: calc(($width - $width / 2) / 2);
  }
}

@media screen and (max-width: 1200px) {

  .header-extra-search {
    &.search_focus {
      width: 160px;
    }
  }
}
.layout-menu-search.ant-select {
  width: 200px;
  margin-right: 20px;
}
</style>
