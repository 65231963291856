<template>
  <a-range-picker v-model:value="innerValue" />
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "dol-date-range",
  props: {
    startDate: {type: String},
    endDate: {type: String},
  },
  emits: ['update:startDate', 'update:endDate', 'change'],
  data() {
    return {
    };
  },
  computed: {
    innerValue: {
      get(){
        if(this.startDate && this.endDate){
          return [dayjs(this.startDate), dayjs(this.endDate)]
        }else{
          return undefined;
        }
      },
      set(v){
        if(v && v.length === 2){
          const startDate = v[0].format('YYYY-MM-DD');
          const endDate = v[1].format('YYYY-MM-DD');
          this.$emit('update:startDate', startDate);
          this.$emit('update:endDate', endDate);
          this.$emit('change', [startDate, endDate]);
        }else{
          this.$emit('update:startDate', undefined);
          this.$emit('update:endDate', undefined);
          this.$emit('change', []);
        }
      }
    },
  },
  methods: {
  },
  created() {
  }
}
</script>
