<template>
  <div class="login-bg">
    <div class="login-left">
      <img class="login-left-img" src="../../../public/static/login/loginImg.jpg">
    </div>
    <div class="login-right">
      <div class="login-right-title">
        <span class="login-right-title-left">真膳美·</span>
        <span>智能云平台</span>
      </div>
      <div class="login-right-box">
        <div class="login-right-box-title">账号登录</div>
        <div class="login-right-box-tips">欢迎使用真膳美·智能云平台管理系统</div>
<!--        <div class="login-right-box-switch">-->
<!--          <span @click="changeLoginType('account')">手机验证码登录</span>-->
<!--          <span @click="changeLoginType('wechat')">微信验证码登录</span>-->
<!--          <div class="login-right-box-switch-bg" :class="[loginType === 'account' ? 'left' : 'right']"></div>-->
<!--        </div>-->
        <div class="box">
          <div class="login-right-box-form" :class="{show: loginType === 'account'}">
            <a-form>
              <a-form-item>
                <a-input v-model:value.trim="loginForm.tenantCode" class="login-item" placeholder="请输入租户号"
                         @pressEnter="login">
                  <template #prefix>
                    <dol-icon type="HomeOutlined" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input v-model:value.trim="loginForm.username" class="login-item" placeholder="请输入用户名"
                         @pressEnter="login">
                  <template #prefix>
                    <dol-icon type="UserOutlined" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input-password v-model:value.trim="loginForm.password" class="login-item" type="password" placeholder="请输入密码"
                                  @pressEnter="login">
                  <template #prefix>
                    <dol-icon type="LockOutlined" />
                  </template>
                </a-input-password>
              </a-form-item>
<!--              <a-form-item>-->
<!--                <a-input v-model:value.trim="loginForm.code" class="login-item" placeholder="请输入验证码"-->
<!--                         @pressEnter="login">-->
<!--                  <template #suffix>-->
<!--                    <div class="get-code">获取验证码</div>-->
<!--                  </template>-->
<!--                </a-input>-->
<!--              </a-form-item>-->
              <a-form-item>
                <a-button class="login-button" :loading="loading" block @click="login">
                  登录
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="login-right-box-form" :class="{show: loginType === 'wechat'}">
            <div class="qrcode">
              <img src="static/qrcode.svg" class="qrcode-img" />
            </div>
          </div>
          <!-- 协议 -->
<!--          <div class="login-right-bottom">-->
<!--            <a-checkbox>-->
<!--              <span>登录即视为同意</span>-->
<!--            </a-checkbox>-->
<!--            <span class="agreement">《真膳美·智能平台隐私协议》</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance } from 'vue';

export default {
  name: 'login',
  components: { UserOutlined, LockOutlined, SafetyCertificateOutlined },
  data() {
    return {
      title: process.env.VUE_APP_WEB_TITLE || 'Dolphin后台管理系统',
      subTitle: process.env.VUE_APP_WEB_TITLE_EN || 'Dolphin Framework System',
      loginForm: {
        tenantCode: '',
        username: '',
        password: '',
      },

      // 登录方式 手机 扫码
      loginType: 'account',
      loading: false,
    };
  },
  computed: {},
  methods: {
    login() {
      if(this.loginType === 'account') {
        this.loading = true;
        this.$store.dispatch('user/login', this.loginForm).then(() => {
          function getFirstMenu(menu){
            if(menu.children && menu.children.length > 0){
              return getFirstMenu(menu.children[0]);
            }else{
              return menu;
            }
          }

          let firstMenu = this.$store.state.user.menuList && this.$store.state.user.menuList[0];
          console.log('firstMenu', firstMenu);
          if(firstMenu){
            const menu = getFirstMenu(firstMenu);
            this.$router.replace(menu.fullPath);
          }else{
            this.$message.error('权限配置有误');
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    changeLoginType(type) {
      this.loginType = type;
    }
  },
  created() {
  },
};
</script>

<style lang="scss">
.login-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient( 180deg, #122875 0%, #0B1847 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  .login-left {
    flex: 1;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-left-img {
    width: 30vw;
    }
  }
  // 页面宽度低于1200
  @media screen and (max-width: 1200px) {
    .login-left {
      display: none;
    }
    .login-right {
      width: 100vw !important;
    }
  }

  .login-right {
    margin-right: 0;
    width: 45vw;
    position: relative;
    background: #060610;
    height: 100%;

    padding: 48px 0 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-right-title{
      font-size: 24px;
      color: #FFFFFF;
      line-height: 28px;
      font-weight: 300;

      .login-right-title-left {
        font-weight: bold;
      }
    }

    .login-right-box {
      margin-top: 16vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      //min-height: 500px;
      .login-right-box-title{
        font-weight: bold;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 33px;
      }

      .login-right-box-tips{
        margin-top: 16px;
        font-size: 13px;
        color: #FFFFFF;
        line-height: 15px;
        opacity: 0.4;
      }

      .login-right-box-switch{
        margin-top: 30px;
        width: 372px;
        height: 48px;
        padding: 4px;
        box-sizing: border-box;
        background: #13131B;
        border-radius: 12px;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        opacity: 0.4;
        display: flex;
        align-items: center;
        position: relative;
        span {
          flex: 1;
          text-align: center;
          z-index: 10;
          line-height: 40px;
          cursor: pointer;
        }

        .login-right-box-switch-bg{
          z-index: 1;
          position: absolute;
          height: 40px;
          width: 182px;
          background: #2A2A32;
          border-radius: 8px;
          transition: all 0.5s;
          user-select: none;

          &.left{
            left: 0;
          }
          &.right{
            left: 186px;
          }
        }
      }
    }

    .login-item-bottom {
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .login-button {
      background: #025AC0;
      border-radius: 12px;
      border: none !important;
      color: #FFFFFF;
      height: 48px;
      line-height: 40px;

      &:hover {
        color: #dddddd;
      }
    }

    .login-item-bottom-right {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .box{
      margin-top: 24px;
      position: relative;
      width: 372px;
      .login-right-box-form{
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 300px;


        &.show{
          opacity: 1;
          z-index: 10;
        }

        .login-item {
          background: unset !important;
          height: 50px !important;
          border-radius: 12px !important;
          border: 1px solid #282837;

          input{
            &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
              -webkit-transition-delay: 99999s;
              -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
            }
          }

          .login-item-left-select{
            height: 30px;
            .ant-select-selection-search-input{
              height: 30px;
            }
          }

          .ant-input-group{
            width: 371px;
            height: 50px;
            box-sizing: border-box;

            .ant-input-group-addon{
              border: unset;
              background: unset;

              .ant-select{
                min-width: 66px;
              }
            }
          }

          .ant-input, .ant-select-selector {
            background: unset;
            border-radius: unset;
            border: unset !important;
            padding-inline: 18px;
            line-height: 40px;
          }

          .get-code{
            font-size: 13px;
            color: #0166FF;
            height: 100%;
            line-height: 48px;
            padding-inline: 16px;
          }
        }

        .qrcode {
          margin-inline: auto;
          background-image: url("../../../public/static/qrcode_bg.svg");
          padding: 24px;
          width: 248px;
          height: 248px;
          box-sizing: border-box;
          .qrcode-img{
            width: 200px;
            height: 200px;
          }
        }
      }

      .login-right-bottom {
        position: absolute;
        top: 360px;
        width: 100%;
        display: flex;
        justify-content: center;
        line-height: 22px;
        span{
          color: rgba(255, 255, 255, 0.4);
        }

        .agreement{
          color: rgba(255, 255, 255, 0.9);
          cursor: pointer;
        }
      }
    }


  }
}
</style>
