<template>
  <a-layout-header class="dol-layout-header">
    <div class="header-title">{{ title }}</div>
    <div class="header-menu">
      <a-menu :selectedKeys="selectMenu" mode="horizontal">
        <a-menu-item v-for="menuInfo in managerMenuList" :key="menuInfo.fullPath || menuInfo.id" @click="linkPage(menuInfo)">
          <dol-icon :type="menuInfo.icon"/>
          {{ $dt(menuInfo, 'name') }}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="header-extra">
      <layout-menu-search/>
<!--      <div class="header-extra-search" :class="searchClass" @click="onSearchFocus">-->
<!--        <img src="../../../public/static/icon/search.svg">-->
<!--        <a-input class="header-extra-search-input" ref="header_extra_search_input" placeholder="请输入..."-->
<!--                 :bordered="false" :maxlength="20" v-model="searchVal"-->
<!--                 @blur="onSearchBlue" />-->
<!--      </div>-->
      <a-dropdown class="account" trigger="hover">
        <span class="account-info">
          <img src="../../../public/static/icon/icon.png" class="account-icon" alt="Avatar">
<!--          <dol-icon type="DownOutlined"/>-->
        </span>
        <template #overlay>
          <div>
            <div class="account-name">{{ userInfo.name }}</div>
            <a-menu @click="accountMenu">
              <a-menu-item key="changePwd">
                修改密码
              </a-menu-item>
              <a-menu-item key="logout">
                退出登录
              </a-menu-item>
            </a-menu>
          </div>
        </template>
      </a-dropdown>
    </div>

    <a-modal title="修改密码" v-model:open="passwordDialog" okText="确认" cancelText="取消" :confirmLoading="changePwdLoading" @ok="changePwd">
      <a-form class="dol-edit-form">
        <a-form-item label="旧密码">
          <a-input v-model:value="passwordForm.oldPassword" type="password"/>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input v-model:value="passwordForm.newPassword" type="password"/>
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input v-model:value="passwordForm.confirmPassword" type="password"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-header>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutMenuSearch from '@/layout/sideMenuLayout/layout-menu-search';

export default {
  name: "layout-header",
  components: { LayoutMenuSearch },
  data () {
    return {
      passwordDialog: false,
      passwordForm: {},
      changePwdLoading: false,

      searchVal: null,
      searchClass: '',
    };
  },
  computed: {
    ...mapGetters(['menuList', 'userInfo', 'langType', "tenantInfo"]),
    managerMenuList () {
      return this.menuList;
    },
    selectMenu () {
      return ['/' + this.$route.path.split('/')[1]];
    },
    title () {
      return this.tenantInfo ? this.tenantInfo.name : process.env.VUE_APP_WEB_TITLE;
    },
  },
  methods: {
    linkPage (menuInfo) {
      function getFirstMenu (menu) {
        if (menu.children && menu.children.length > 0) {
          return getFirstMenu(menu.children[0]);
        } else {
          return menu;
        }
      }

      const firstMenu = getFirstMenu(menuInfo);
      this.$router.push(firstMenu.fullPath);
    },
    accountMenu ({ key }) {
      switch (key) {
        case 'changePwd':
          this.passwordForm = {};
          this.passwordDialog = true;
          break;
        case 'logout':
          this.$router.replace('/login').then(() => {
            this.$store.dispatch('user/logout').then(() => {
            });
          });
          break;
      }
    },
    changePwd () {
      if (this.passwordForm.oldPassword && this.passwordForm.newPassword && this.passwordForm.confirmPassword) {
        if (this.passwordForm.newPassword === this.passwordForm.confirmPassword) {
          this.changePwdLoading = true;
          this.$ajax({
            url: this.$api.system.auth.user.changeSelfPwd,
            method: 'post',
            data: this.passwordForm,
          }).then(result => {
            this.changePwdLoading = false;
            this.$message.info(result.message);
            this.passwordDialog = false;
          }).catch(e => {
            this.changePwdLoading = false;
          });
        } else {
          this.$message.info('两次密码输入不一致');
        }
      } else {
        this.$message.info('密码不能为空');
      }
    },

    onSearchFocus() {
      this.searchClass = 'search_focus';
      // 设置焦点
      this.$nextTick(() => {
        this.$refs.header_extra_search_input.focus();
      });
    },
    onSearchBlue() {
      this.searchClass = '';
      this.searchVal = null;
    },
  },
};
</script>

<style lang="scss">
.ant-layout-header.dol-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  @media screen and (max-width: 1200px) {
    .header-title {
      opacity: 0;
    }
  }

  .header-title {
    font-size: 20px;
    font-family: 'Microsoft Ya Hei', 'sans-serif';
    font-weight: 700;

    margin-right: 20px;
    flex: 1;
  }

  .header-menu {
    border-radius: 18px;
    background-image: linear-gradient(to bottom, rgba(59, 59, 67, 1), rgba(18, 18, 26, 1));
    padding: 1px;

    .ant-menu.ant-menu-root.ant-menu-horizontal {
      border-bottom: unset;
      box-sizing: border-box;
      background: #0F0F19;
      padding: 0 24px;
      border-radius: 18px;
    }

    .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
      border-bottom: unset !important;
      transition: unset !important;
    }
  }

  .header-extra {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    .account-info {
      display: flex;
      align-items: center;

      img.account-icon {
        height: 40px;
      }

      .dol-icon {
        font-size: 10px;
        margin-left: 2px;
      }
    }
  }
}
</style>
