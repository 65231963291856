import { createRouter, createWebHistory } from 'vue-router'

import { routes, whiteList } from './routeConfig';
import vuex from '@/store/index';

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_CONTEXT_PATH),
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
router.beforeEach((to, from, next) => {
  Promise.resolve().then(() => {
    // return vuex.dispatch('lang/checkLang');
  // }).then(() => {
    return vuex.dispatch('user/hasLogin');
  }).catch(() => {
    return vuex.dispatch('user/whiteList', to.path);
  }).catch(() => { //session 自动登录
    return vuex.dispatch('user/autoLoginBySession');
  }).catch(() => { //cookie 自动登录
    return vuex.dispatch('user/autoLoginByCookie');
  }).catch(() => { //code 自动登录
    return vuex.dispatch('user/autoLoginByCode', to.query);
  }).catch(() => { //钉钉自动登录
    return vuex.dispatch('user/autoLoginByDingTalk', to.query);
  }).then(({ replace } = {}) => {
    if (replace) {  // 重新让路由查一次 避免进404
      next({ path: to.path, replace: true, query: to.query})
    } else {
      next();
    }
  }).catch(() => {
    next({ path: '/login', replace: true });
  })
});

router.afterEach((to, from, failure) => {
  if (window.history.state.replaced) {
    vuex.commit('app/removeMenuTab', from.path);
  }
  vuex.commit('app/setActiveMenu', to);
})

export default router
