<template>
  <div class="index-page index">
    <div class="index_top">
      <div class="index_top_header">
        <template v-if="$auth('FUNC_TENANT')">
          <a-form-item>
            <dol-select v-model:value="queryForm.tenant" source="server" :url="$api.tenant.tenant.options" :empty-option="false" placeholder="请选择租户" @change="query"/>
          </a-form-item>
          <a-form-item>
            <dol-select v-model:value="queryForm.store" source="server" :url="$api.system.auth.org.tenantStoreOptions" :empty-option="false" placeholder="请选择门店" @change="query"/>
          </a-form-item>
        </template>
        <template v-else>
          <dol-select v-model:value="queryForm.store" source="server" :url="$api.system.auth.org.options" :query-params="{type: 'store'}" :empty-option="false" placeholder="请选择门店" @change="query"/>
        </template>

<!--                <div class="flex">-->
<!--                  <svg-icon size="16" name="temperature" />-->
<!--                  {{ temperature }}-->
<!--                </div>-->
<!--                <div class="flex">-->
<!--                  <svg-icon size="16" name="humidity" />-->
<!--                  {{ humidity }}-->
<!--                </div>-->
<!--                <div class="flex">-->
<!--                  <svg-icon size="16" name="electric" />-->
<!--                  {{ electric }}-->
<!--                </div>-->
      </div>
      <div class="index_top_body">
        <div class="index_top_body_left">
          <div class="title">
            <span>{{ greeting }}，</span>
            <span>{{ userName }}</span>
          </div>
          <div class="time">
            {{ time }}
          </div>
        </div>
        <div class="index_top_body_right">
          <div class="index_top_body_right_item" v-for="item of buttons" :key="item.icon">
            <svg-icon :name="item.icon" :size="100"/>
            <div class="item_right">
              <div class="item_right_text">{{ item.text }}</div>
              <div class="item_right_num">{{ item.num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index_body">
      <!--      <div class="index_body_left">-->
      <!--        <real-time-dishes-output />-->
      <!--        <div class="index_body_left_items mt-12">-->
      <!--          <water-volume-monitoring :water="3.4" dtd="33%" width="calc(50% - 12px)" />-->
      <!--          <energy-consumption-monitoring :energy-consumption="9999" dtd="-12%" width="calc(50% - 12px)" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="index_body_right">
        <dol-pagination
          ref="list"
          :query-data="queryForm"
          :url="$api.device.device.list"
          :query-form-flag="false"
          :title-flag="false">
          <template #default="{rows, total}">
            <div class="dish_body_list" :class="{no_data: !total}">
              <device-info v-for="item in rows" :device-info="item" :key="item.id" :operation-flag="false"/>
            </div>
          </template>
        </dol-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DolSelect from '@/components/dolphin-ant-design/form/dol-select.vue';
import SvgIcon from '@/components/base/svgIcon.vue';
import RealTimeDishesOutput from '@/components/index/realTimeDishesOutput.vue';
import WaterVolumeMonitoring from '@/components/index/waterVolumeMonitoring.vue';
import EnergyConsumptionMonitoring from '@/components/index/energyConsumptionMonitoring.vue';
import DeviceMonitoring from '@/components/index/deviceMonitoring.vue';
import DeviceInfo from '@/components/base/device-info.vue';
import DolPagination from '@/components/dolphin-ant-design/display/dol-pagination.vue';
import commonPageScript from '@/components/common/commonPageScript';
import { mapGetters } from 'vuex';

export default {
  name: "index",
  components: { DolPagination, DeviceInfo, DeviceMonitoring, EnergyConsumptionMonitoring, WaterVolumeMonitoring, RealTimeDishesOutput, SvgIcon, DolSelect },
  mixins: [commonPageScript],
  data () {
    return {
      shopId: '',
      countObj: {},
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    temperature () {
      return '26℃';
    },
    // 湿度
    humidity () {
      return '60%';
    },
    // 电
    electric () {
      return '100KW·H';
    },
    userName () {
      return this.userInfo?.name || '';
    },
    // 早上/中午/下午好
    greeting () {
      const hour = new Date().getHours();
      if (hour < 6) {
        return '凌晨了';
      } else if (hour < 10) {
        return '早上好';
      } else if (hour < 14) {
        return '中午好';
      } else if (hour < 18) {
        return '下午好';
      } else if (hour < 22) {
        return '晚上好';
      } else {
        return '夜深了';
      }
    },
    time () {
      return this.$dayjs().format('ddd MM.DD YYYY');
    },
    // 操作按钮
    buttons () {
      return [
        {
          icon: 'online',
          text: '设备数',
          num: this.countObj.device ?? 0,
          onClick: () => {
          }
        },
        {
          icon: 'offline',
          text: '菜品数',
          num: this.countObj.dish ?? 0,
          onClick: () => {
          }
        },
        {
          icon: 'employees',
          text: '用户数',
          num: this.countObj.user ?? 0,
          onClick: () => {
          }
        },
        {
          icon: 'output',
          text: '菜谱数',
          num: this.countObj.recipe ?? 0,
          onClick: () => {
          }
        },
      ];
    },
  },
  methods: {
    findStatistics () {
      this.$ajax({
        url: this.$api.report.indexCount,
        method: 'get'
      }).then(({ data }) => {
        this.countObj = data;
      });
    },
    onLoadShop (res) {
      if (res.code === 200 && res.data?.length) {
        this.shopId = res.data[0].id;
      }
    },
    onShopChange () {
      this.$refs.list?.load();
    }
  },
  created () {
    this.findStatistics();
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}

.index-page {
  position: relative;
  height: 100%;
  width: 100%;
  border-top: unset !important;
  padding: 24px 36px;

  &.index {
    .index_top {
      .index_top_header {
        @extend .flex;
        gap: 56px;

        :deep(.ant-select) {
          width: 250px;
        }
      }

      .index_top_body {
        @extend .flex;
        margin-top: 32px;
        justify-content: space-between;
        gap: 32px;
        padding-bottom: 45px;
        border-bottom: 1px solid #2A2A32;
        flex-wrap: wrap;

        .index_top_body_left {
          color: #FFFFFF;

          .title {
            font-weight: 500;
            font-size: 42px;
            line-height: 49px;
            @extend .flex;
            flex-wrap: wrap;
          }

          .time {
            margin-top: 16px;
            font-size: 18px;
            line-height: 21px;
            opacity: 0.6;
          }
        }

        .index_top_body_right {
          @extend .flex;
          column-gap: 32px;
          row-gap: 16px;
          flex-wrap: wrap;

          .index_top_body_right_item {
            @extend .flex;

            .item_right {
              padding: 6px 0 6px 24px;
              width: 132px;

              .item_right_text {
                font-size: 15px;
                color: #FFFFFF;
                line-height: 18px;
              }

              .item_right_num {
                margin-top: 18px;
                font-size: 38px;
                color: #FFFFFF;
                line-height: 45px;
              }
            }
          }
        }
      }
    }

    .index_body {
      padding-block: 45px;
      display: flex;
      gap: 32px;
      flex-wrap: wrap;

      .index_body_left {
        min-width: 540px;

        .index_body_left_items {
          display: flex;
          gap: 24px;
        }
      }

      .index_body_right {
        flex: 1;
        min-width: 600px;
        display: flex;
        flex-wrap: wrap;

        .ant-card {
          width: 100%;
        }

        .dish_body_list {
          display: flex;
          flex-wrap: wrap;
          gap: 32px;
          box-sizing: border-box;

          &.no_data {
            padding: 32px;

            &:before {
              content: '暂无数据';
              width: 100%;
              height: 30vh;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #3c3c3c;
            }
          }
        }
      }
    }
  }
}
</style>
