//updated by dolphin on 5/29/2024, 3:33:33 PM.

"use strict";
const API = {
  "base": {
    "login": {
      "login": "/login",
      "refresh": "/system/auth/user/refresh",
      "autoLogin": "/login/autoLogin/{id}",
      "register": "/login/register",
      "forgetPassword": "/login/forgetPassword"
    }
  },
  "system": {
    "auth": {
      "user": {
        "@extend": "list",
        "changePwd": "@/changePwd",
        "changeSelfPwd": "@/changeSelfPwd",
        "setStatus": "@/setStatus",
      }, "role": { "@extend": "list", "setDefaultRole": "@/setDefaultRole" }, "org": { "@extend": "tree", "tenantStoreOptions": "@/tenantStoreOptions" }
    },
    "tool": {
      "file": { "@extend": "list", "qr": "@/qr/", "base64": "@/base64" },
      "mail": { "@extend": "list", "send": "/system/tool/mail/send" },
      "job": {
        "@extend": "list",
        "getJobMethods": "@/getJobMethods",
        "start": "@/start/{id}",
        "stop": "@/stop/{id}",
        "run": "@/run/{id}",
        "reset": "@/reset/{id}",
        "clearLog": "@/clearLog/{id}",
        "logList": "/system/log/jobLog/list",
        "getConfig": "@/getConfig/{func}"
      }
    },
    "dict": { "@extend": "list" },
    "systemConfig": { "@extend": "list", "getConfig": "@/getConfig/{code}", "updateConfig": "@/updateConfig/{code}" },
    "log": {
      "loginLog": { "@extend": "list" },
      "auditLog": { "@extend": "list" },
    },
    "resource": { "@extend": "tree", "getTenantResource": "@/getTenantResource" }
  },
  "tenant": {
    "tenant": { "@extend": "list", "createTenant": "@/createTenant", "setStatus": "@/setStatus" },
    "module": { "@extend": "list" },
    "store": { "@extend": "list", "listByOrg": "@/listByOrg" },
  },
  "device": {
    "deviceType": { "@extend": "list", "tenantList": "@/tenantList", "alias": "@/alias", "tenantOptions": "@/tenantOptions" },
    "deviceControl": { "@extend": "list", "alias": "@/alias" },
    "device": { "@extend": "list", "countByNetStatus": "@/countByNetStatus", "transfer": "@/transfer", "storeDevice": "@/storeDevice" },
    "deviceLog": { "@extend": "list" },
    "maintenancePlan": { "@extend": "list", "findWithRecords": "@/findWithRecords", "addRecord": "@/addRecord" },
    "maintenanceRecord": { "@extend": "list" },
    "repairRequest": { "@extend": "list", "addRecord": "@/addRecord" },
    "repairRecord": { "@extend": "list" },
  },
  "dish": {
    "category": { "@extend": "tree" },
    "dishTag": { "@extend": "list" },
    "dish": { "@extend": "list", "countByCategory": "@/countByCategory", "listWithRecipes": "@/listWithRecipes", "listForPublish": "@/listForPublish", "listForCopy": "@/listForCopy" },
    "recipe": { "@extend": "list", "findWithDetail": "@/findWithDetail" },
    "recipeDetail": { "@extend": "list", "saveRecipeDetails": "@/saveRecipeDetails" },
    "storeDish": { "@extend": "list", "saveStoreDish": "@/saveStoreDish", "copyDevice": "@/copyDevice" },
  },
  "cook": {
    "cookRecord": { "@extend": "list" },
  },
  "report": {
    "indexCount": "@/indexCount",
    "cookCountByDay": "@/cookCountByDay",
    "cookCountByUser": "@/cookCountByUser",
    "cookCountByDish": "@/cookCountByDish",
    "cookCountByDevice": "@/cookCountByDevice",
    "cookCountByStatus": "@/cookCountByStatus",
  },
};

export default API;
