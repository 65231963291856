<template>
  <div class="device_bg">
    <div class="device" :class="{device_error: deviceInfo.netStatus !== 'online', has_operation: operationFlag}">
      <div class="device_status" :class="deviceInfo.netStatus">
        <div class="status" />
        <dol-dict :value="deviceInfo.netStatus" dict-code="netStatus" />
      </div>
      <div class="device_img">
        <a-image v-if="deviceInfo.image" width="168px" height="100px" :src="`${$Dolphin.path.publicPath}${deviceInfo.image}`" />
        <a-image v-else-if="deviceInfo.deviceType && deviceInfo.deviceType.image" width="168px" height="100px" :src="`${$Dolphin.path.publicPath}${deviceInfo.deviceType.image}`" />
        <a-image v-else width="168px" height="100px" src="static/dish/dish.svg" />
      </div>
      <div class="device_info">
        <!-- todo温度 功率 -->
<!--        <template v-if="deviceInfo.temperature">-->
<!--        <a-flex align="center">-->
<!--          <img class="device_info_icon" src="static/device/thermometer.svg"  alt=""/>-->
<!--          <span>{{ deviceInfo.temperature || 27 }}℃</span>-->
<!--        </a-flex>-->
<!--&lt;!&ndash;        <template v-if="deviceInfo.power">&ndash;&gt;-->
<!--        <a-flex align="center">-->
<!--          <img class="device_info_icon" src="static/device/lightning.svg" alt="">-->
<!--          <span>{{ deviceInfo.power || 10 }}KW·H</span>-->
<!--        </a-flex>-->
      </div>
      <div class="device_name">{{ deviceInfo.name }}</div>
      <div class="device_num">设备状态：<dol-dict :value="deviceInfo.status" dict-code="deviceStatus" /></div>
      <div class="device_state">当前菜品：{{ deviceInfo.dishName }}</div>
      <div class="device_state">当前火力：{{ deviceInfo.powerLevel }}</div>
      <div class="device_state">门店：{{ deviceInfo.store?.name || '未分配' }}</div>
      <div class="device_btns" v-if="operationFlag">
        <div class="device_btns_item" @click="edit">
          <img src="static/device/setting2.svg" />
          <span>资料</span>
        </div>
        <div class="device_btns_item" @click="repair">
          <img src="static/device/setting1.svg" />
          <span>报修</span>
        </div>
        <div class="device_btns_item" @click="maintenance">
          <img src="static/device/setting4.svg" />
          <span>保养</span>
        </div>
        <div v-if="deviceInfo.store" class="device_btns_item" @click="transfer">
          <img src="static/device/setting3.svg" />
          <span>转移</span>
        </div>
        <div v-else class="device_btns_item" @click="transfer">
          <img src="static/device/setting3.svg" />
          <span>分配</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 设备
export default {
  name: 'device-info',
  props: {
    deviceInfo: {
      type: Object, default: () => ({}),
    },
    operationFlag: {type: Boolean, default: true}
  },
  emits: ['edit', 'repair', 'maintenance', 'transfer'],
  methods: {
    edit() {
      this.$emit('edit', this.deviceInfo);
    },
    repair() {
      this.$emit('repair', this.deviceInfo);
    },
    maintenance() {
      this.$emit('maintenance', this.deviceInfo);
    },
    transfer() {
      this.$emit('transfer', this.deviceInfo);
    }
  }
};
</script>

<style scoped lang="scss">
.device_bg{
  background-image: linear-gradient(to bottom, rgba(59, 59, 67, 1), rgba(18, 18, 26, 1));
  padding: 1px;
  border-radius: 24px;

}
.device {
  width: 200px;
  height: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  background: #0A0A14;
  border-radius: 24px;
  position: relative;
  &.has_operation{
    padding: 24px 16px 80px;
  }
  &.device_error{
    background: radial-gradient(95% 75% at 50% 35%, #0A0A14 70%, #EC4B54 100%);
    border-radius: 24px 24px 24px 24px;;
  }

  .device_img{
    width: 168px;
    height: 100px;
  }

  .device_info{
    display: flex;
    height: 28px;
    align-items: center;
    gap: 4px;
    color: #FFFFFF;
    opacity: 0.6;
    justify-content: center;

    .device_info_icon{
      height: 18px;
    }
  }

  .device_name{
    margin-top: 6px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 22px;
  }

  .device_tags{
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 8px;

    .device_tags_item{
      font-size: 12px;
      color: #FFFFFF;
      line-height: 20px;
      padding-inline: 6px;
      background: #636890;
      border-radius: 6px;
    }
  }

  .device_num{
    margin-top: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 16px;
  }

  .device_state{
    margin-top: 4px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 16px;
  }

  .device_btns{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 18px;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
    height: 46px;

    .device_btns_item{
      width: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      img{
        width: 28px;
        height: 28px;
      }
      span {
        font-size: 11px;
        color: #FFFFFF;
        margin-top: 5px;
        line-height: 13px;
        opacity: 0.6;
      }
    }
  }

  .device_status{
    position: absolute;
    height: 28px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 4px 12px;
    top: 18px;
    left: 18px;
    font-size: 13px;
    z-index: 100;

    .status{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 6px;
    }
    &.online {
      background: rgba(255, 255, 255, 0.06);
      .status{
        background: #70C787;
      }
    }

    &.offline{
      background: #EC4B54;
      .status{
        background: #F3F2E9;
      }
    }

    &.inactive{
      background: #EC4B54;
      .status{
        background: #F3F2E9;
      }
    }
  }
}
</style>
