<template>
  <a-layout class="side-menu-layout">
    <layout-header />
    <a-layout>
      <layout-sider />
      <a-layout-content>
        <app-main />
      </a-layout-content>
      <!--      <layout-footer />-->
    </a-layout>
  </a-layout>
</template>

<script>
import appMain from '@/layout/app-main';
import layoutSider from './layout-sider';
import layoutHeader from './layout-header';
import layoutFooter from './layout-footer';

export default {
  name: 'index',
  components: { appMain, layoutHeader, layoutFooter, layoutSider },
};
</script>

<style lang="scss">
@import "../../assets/scss/constant";

.ant-layout-sider, .ant-layout-content {
  > .app-main > div {
    border-top: 1px solid #2A2A32;
  }
}

.ant-layout-sider {
  .ant-menu {
    padding-top: 16px;
  }
}

.ant-layout-content {
  border-left: 1px solid #2A2A32;
}

.side-menu-layout {
  .ant-layout-header {
    height: $headerHeight;
    background: $backgroundBase;
    line-height: 56px;

    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-submenu-selected {
      color: $white;

      &::after {
        content: ' ';
        background: $primaryColor;
        border-bottom-width: 0;
        border-bottom-color: unset;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 auto 6px;
      }
    }

    .ant-menu-horizontal > .ant-menu-item:hover::after,
    .ant-menu-horizontal > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal > .ant-menu-item-active::after,
    .ant-menu-horizontal > .ant-menu-submenu-active::after,
    .ant-menu-horizontal > .ant-menu-item-open::after,
    .ant-menu-horizontal > .ant-menu-submenu-open::after {
      border-bottom-width: 0;
    }
  }

  .ant-menu-root {
    background: $backgroundBase;
    font-size: 16px;
    color: rgba($white, 0.4)
  }

  .app-main {
    height: calc(100vh - #{$headerHeight} /* - 32px*/
    ); /* 总高100vh - 页头 - 页脚32px */
    width: 100%;
    position: relative;
    overflow: auto;
    //padding: 24px;
  }
}

.ant-layout-sider.dol-layout-sider {
  .ant-menu-item.ant-menu-item-selected {
    .ant-menu-title-content{
      color: $fontColor;
    }
    .dol-icon {
      color: $fontColor;
    }
  }
}
</style>
