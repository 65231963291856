<template>
  <div class="water" :style="{width: width}">
    <div class="water_title">水量监测</div>
    <div class="water_body"></div>
    <a-flex align="center" :gap="2" class="water_bottom">
      <svg-icon name="water" size="38" />
      <div class="water_bottom_num">{{ water }}</div>
      <div class="water_bottom_unit">T</div>
      <div class="water_bottom_text">{{ dtd }}</div>
    </a-flex>
  </div>
</template>

<script>
// 水量监控
import SvgIcon from '@/components/base/svgIcon.vue';

export default {
  name: 'waterVolumeMonitoring',
  components: { SvgIcon },
  props: {
    width: {type: [Number, String], default: '100%'},
    water: {type: [Number, String], default: ''},
    dtd: {type: [Number, String], default: '-12%'}, // 天比天增长率
  }
};
</script>

<style scoped lang="scss">
.water {
  display: flex;
  flex-direction: column;
  height: 230px;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 18px;
  gap: 12px;
  .water_title{
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-weight: bold;
  }
  .water_body{
    width: 100%;
    flex: 1;
  }
  .water_bottom {
    height: 50px;
    gap: 2px;

    .water_bottom_num{
      font-weight: bold;
      font-size: 42px;
      color: #000000;
      padding-left: 4px;
    }
    .water_bottom_unit{
      font-size: 21px;
      color: rgba(0, 0, 0, 0.2);
      line-height: 25px;
      height: 50px;
    }
    .water_bottom_text{
      font-size: 21px;
      color: #70C787;
    }
  }
}
</style>
