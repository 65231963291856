<script>
import { h } from 'vue';
import DolCard from './dol-card';
import DolDict from './dol-dict.vue';
import DolSelect from '../form/dol-select.vue';
import $Dolphin from 'dolphin-framework-vue/core/Dol-core';
import { Table, Tag, Input, Image, InputNumber } from 'ant-design-vue';
import dayjs from 'dayjs';

import requestMixins from '../common/requestMixins';

const COMMON_CELL_RENDER = function(options) {
  const {value, column, record, index} = options;
  let render;
  switch (column.fieldType){
    case 'rowIndex': render = h('span', this.pageSize * (this.pageNumber - 1) + index + 1); break;
    case 'boolean': render = h('span', typeof value === 'boolean' ? (value ? (column.trueText || '是') : (column.falseText || '否')) : (column.nullText || '')); break;
    case 'dict': render = value && h(DolDict, {dictCode: column.dictCode, value: value, tag: column.tag, color: column.tagColor}); break;
    case 'ref': render = h('span', value && value[column.labelField || 'name']); break;
    case 'refArray': render = h('span', value ? value.map(item => item[column.labelField || 'name']).join(column.separator || ', ') : ''); break;
    case 'tagArray': render = tagArrayRender(options); break;
    case 'pre': render = h('pre', {style: {'white-space': 'pre-wrap'}}, value); break;
    case 'html': render = h({ template: value }); break;
    case 'wordBreakAll': render = h('span', {class: 'word-break-all'}, value); break;
    case 'object': render = h('div', value ? Object.keys(value).map(key => h('div', `${key} = ${value[key]}`)) : ''); break;
    case 'editInput': render = h(Input, {value, 'onUpdate:value': v => record[column.dataIndex] = v, onChange: (v, o) => typeof column.onChange === 'function' && column.onChange(v, o, record), style: {width: '100%'}}); break;
    case 'editSelect': render = h(DolSelect, {value, 'onUpdate:value': v => record[column.dataIndex] = v, ...column.editOption, onChange: (v, o) => typeof column.onChange === 'function' && column.onChange(v, o, record), style: {width: '100%'}}); break;
    case 'editNumber': render = h(InputNumber, {value, 'onUpdate:value': v => record[column.dataIndex] = v, onChange: (v, o) => typeof column.onChange === 'function' && column.onChange(v, o, record), style: {width: '100%'}}); break;
    case 'image': render = value ? h(Image, {src: $Dolphin.path.publicPath + value, style: {height: '60px'}}) : null; break;
    case 'timezone': render = value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : null; break;
    case 'params': render = paramsRender(options); break;
  }
  return render;
}

function tagArrayRender({value, column, record, index}){
  let tagList = [];
  if(value && value.length > 0){
    value.forEach(item => {
      tagList.push(h(Tag, {color: column.color}, () => (item[column.labelField || 'name'])));
    });
  }
  return h('div', tagList);
}
function paramsRender({value, column, record, index}){
  let params = [];
  if(value && value.length > 0){
    value.forEach(item => {
      params.push(h('div', `${item[column.keyField || 'key']} = ${item[column.valueField || 'value']}`));
    });
  }
  return h('div', params);
}

export default {
  name: 'dol-list',
  mixins: [requestMixins],
  emits: ['row-click'],
  props: {
    cardFlag: { type: Boolean, default: true },
    cardClass: { type: String },
    cardSize: { type: String, default: 'small' },
    title: { type: String, default: '列表' },
    titleFlag: { type: Boolean, default: true },

    columns: { type: Array, default: () => [] },  //列信息配置

    rowIndex: { type: Boolean, default: true },
    rowIndexFixed: { type: Boolean, default: false },
    size: { type: String, default: 'small' },
    rowKey: { type: String, default: 'id'},
    bordered: {type: Boolean, default: false},

    selectedRowKeys: { type: Array, default: null },
    selectedRows: { type: Array, default: null },
    selectionGetCheckboxProps: { type: Function, default: null },
    selectionType: { type: String, default: null },

    scrollX: {default: null},
    scrollY: {default: null},
    scroll: {type: Object},
  },
  computed: {
    innerColumns(){
      let innerColumns = [...this.columns];
      if(this.rowIndex){
        const rowIndexColumn = { dataIndex: '#', title: '#', align: 'center', width: '35px', fieldType: 'rowIndex' };
        if(this.rowIndexFixed){
          rowIndexColumn.fixed = 'left';
        }
        innerColumns.unshift(rowIndexColumn);
      }
      return innerColumns;
    },
    paginationParam() {
      if (this.pagination) {
        let params = {
          total: this.innerTotal,
          showSizeChanger: this.paginationShowSizeChanger,
          pageSizeOptions: this.pageSizeOption,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          showQuickJumper: this.paginationShowQuickJumper,
          size: 'default',
        };
        if (this.paginationShowTotal) {
          params.showTotal = (total, range) => `第 ${range[0]}-${range[1]} 条，共 ${total} 条`;
        }
        return params;
      } else {
        return false;
      }
    },
  },
  methods:{
    onSelectChange(selectedRowKeys, selectedRows) {
      this.$emit('update:selectedRowKeys', selectedRowKeys);
      this.$emit('update:selectedRows', selectedRows);
      this.$emit('onSelectChange', selectedRowKeys, selectedRows);
    },
    onPageChange(pagination, filters, sorter) {
      this.pageSize = pagination.pageSize;
      this.pageNumber = pagination.current;

      if (this.paginationType === 'server') {
        if (sorter) {
          this.sorter = sorter;
        }
        this.load();
      }
    },
  },
  created(){
    if (this.source === 'server' && this.initDataFlag) {
      this.query();
    }
  },
  render(){
    const cardProps = {
      class: `dolphin-list ${this.cardClass}`,
      title: this.title,
      titleFlag: this.titleFlag,
      cardFlag: this.cardFlag,
      size: this.cardSize,
    }

    const tableProps = {
      columns: this.innerColumns,
      dataSource: this.innerData,

      pagination: this.paginationParam,

      size: this.size,
      rowKey: this.rowKey,
      bordered: this.bordered,

      loading: this.loadingFlag,

      customRow: record => ({ onClick: event => this.$emit('row-click', {event, record}) }),

      onChange: this.onPageChange,
    }

    if (this.selectedRowKeys) {
      tableProps.rowSelection = {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
      if (this.selectionGetCheckboxProps) {
        tableProps.rowSelection.getCheckboxProps = this.selectionGetCheckboxProps;
      }
      if (this.selectionType) {
        tableProps.rowSelection.type = this.selectionType;
      }
    }

    if (this.scroll) {
      tableProps.scroll = this.scroll;
    } else if (this.scrollX || this.scrollY) {
      tableProps.scroll = {};
      if (this.scrollX) tableProps.scroll.x = this.scrollX;
      if (this.scrollY) tableProps.scroll.y = this.scrollY;
    }

    return h(DolCard, cardProps, {
      extra: this.$slots.extra,
      queryForm: this.$slots.queryForm,
      default: () => h(Table, tableProps, {
        headerCell: params => {
          if(params.dataIndex === 'operation'){
            return [...this.$slots.headerCell && this.$slots.headerCell(params), h()];
          }else{
            return this.$slots.headerCell && this.$slots.headerCell(params);
          }
        },
        bodyCell: params => COMMON_CELL_RENDER.call(this, params) || (this.$slots.bodyCell && this.$slots.bodyCell(params)),
      }),
    })
  },
}
</script>

<style lang="scss">
</style>
