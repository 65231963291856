<template>
  <div class="dol-radio-customer">
    <template v-for="(o, i) in options">
      <div
        class="dol-radio-customer__item"
        :class="{active: o[valueFieldFinal] === value}"
        :style="{'margin-left': i > 0 ? gutter + 'px' : 0}"
        @click="pick(o)"
      >
        <slot :option="o">{{o[labelFieldFinal]}}</slot>
      </div>
    </template>
  </div>
</template>

<script>
import requestMixins from '../common/requestMixins';

export default {
  name: "dol-radio-customer",
  mixins: [requestMixins],
  props: {
    value: {},
    keyField: { type: String },        //key  修改了入参的名字以便使用 keyField不传的时候默认会使用valueField
    labelField: { type: String },       //label
    valueField: { type: String },          //value

    source: { type: String, default: 'dict' },            //数据源，server | local | dict

    gutter: { type: Number, default: 12 }, //间距
  },
  emits: ['update:value', 'change'],
  data() {
    this.search = this.$Dolphin.debounce(this.search, 500);
    return {
      searchValue: '',
    };
  },
  computed: {
    keyFieldFinal() {
      if (this.keyField) return this.keyField;
      return this.valueFieldFinal;
    },
    labelFieldFinal() {
      if (this.labelField) return this.labelField;
      return this.source === 'server' ? 'name' : 'text';
    },
    valueFieldFinal() {
      if (this.valueField) return this.valueField;
      return this.source === 'server' ? 'id' : 'code';
    },
    options() {
      if (this.source === 'server' || this.searchValue === '') {
        return this.innerData;
      } else {
        return this.innerData.filter(item => {
          const matchLabel = new RegExp(this.searchValue, 'i').test(item[this.labelFieldFinal]);
          const matchValue = this.value instanceof Array ? this.value.find(item[this.valueFieldFinal]) : item[this.valueFieldFinal] === this.value;
          return matchLabel || matchValue;
        });
      }
    }
  },
  watch: {
    url() {
      this.load();
    },
    dictCode() {
      this.load();
    },
  },
  methods: {
    pick(option){
      this.$emit('update:value', option[this.valueFieldFinal]);
      this.$emit('change', option[this.valueFieldFinal], option);
    },
  },
  created() {
    this.query();
  },
};
</script>

<style lang="scss">
.dol-radio-customer {
  display: flex;
  .dol-radio-customer__item{
    flex: 1;
    cursor: pointer;
    align-items: center;
  }
}
</style>
