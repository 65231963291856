<template>
  <a-tag v-if="tag" :color="color">{{ showLabel }}</a-tag>
  <span v-else>{{ showLabel }}</span>
</template>

<script>
export default {
  name: 'dol-dict',
  props: {
    code: {type: String},            //数据源，server | local
    dictCode: {type: String},            //数据源，server | local
    labelField: {type: String},            //数据源，server | local
    option: {},            //数据源，server | local
    value: {type: String},            //数据源，server | local
    formatter: {type: Function, default: null},            //数据源，server | local
    tag: {type: Boolean, default: false},            //数据源，server | local
    color: {type: String},            //数据源，server | local
  },
  data() {
    return {
      text: '',
    }
  },
  computed: {
    showLabel() {
      if (this.formatter && typeof this.formatter === 'function') {
        return this.formatter(this.text);
      } else {
        return this.text;
      }
    },
    _option() {
      return this.value || this.option;
    },
    _code() {
      return this.dictCode || this.code;
    },
    _labelField() {
      return this.labelField || 'name';
    }
  },
  methods: {
    getText() {
      if (this._option && this._code) {
        this.$dict(this._code, this._option, {labelField: this._labelField}).then(text => {
          this.text = text || '';
        }).catch(e => {
          this.text = '';
          console.error(e);
        });
      } else {
        this.text = '';
      }
    }
  },
  watch: {
    code() {
      this.getText();
    },
    dictCode() {
      this.getText();
    },
    value() {
      this.getText();
    },
    option() {
      this.getText();
    },
    labelField() {
      this.getText();
    },
  },
  created() {
    this.getText();
  }
}
</script>
