<template>
  <a-date-picker
    v-model:value="date"
    :class="{'dol-date': true, 'dol-date-time': showTime}"
    :style="{width: width}"
    :showTime="showTime"
    :format="formatInside"
    :allowClear="allowClear"
    :disabled="disabled"
    :disabledDate="disabledDate"
    :placeholder="placeholder"
    :size="size"
    @change="onChange"
  />
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "dol-date",
  props: {
    value: {type: String},
    disabledDate: {},
    format: {type: String,},   //format
    allowClear: {type: Boolean, default: true},     //是否显示清除按钮
    showTime: {type: Boolean, default: false},     //是否附带时间选择
    disabled: {type: Boolean, default: false},     //禁用
    placeholder: {type: String, default: '请选择日期'},     //placeholder
    size: {type: String},     //大小large||small
    width: {type: String, default: '100%'},
    isoDate: {type: Boolean, default: false}, //是否支持多时区，需要前后台配合
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      date: this.value ? (this.isoDate ? this.dayjs(this.value) : this.dayjs(this.value, this.formatInside)) : null,
    };
  },
  watch: {
    value(val) {
      val ? this.date = (this.isoDate ? this.dayjs(val) : this.dayjs(val, this.formatInside)) : this.date = null;
    }
  },
  computed: {
    formatInside() {
      if (this.format) {
        return this.format;
      } else if (!this.showTime) {
        return 'YYYY-MM-DD';
      } else {
        return 'YYYY-MM-DD HH:mm:ss';
      }
    },
  },
  methods: {
    dayjs,
    onChange(date, dateString) {
      this.$emit('update:value', this.isoDate ? date.toISOString() : dateString);
      this.$emit('change', date, dateString);
    }
  },
  created() {
  }
}
</script>
