<template>
  <a-sub-menu :key="menuInfo.fullPath">
    <template #icon>
      <dol-icon v-if="menuInfo.icon" :type="menuInfo.icon" />
    </template>
    <template #title>{{ $dt(menuInfo, 'name') }}</template>
    <template v-for="item in menuInfo.children">
      <template v-if="item.children && item.children.length > 0">
        <layout-sub-menu :menu-info="item" />
      </template>
      <template v-else>
        <a-menu-item :key="item.fullPath" @click="$router.push(item.fullPath)">
          <template #icon>
            <dol-icon v-if="item.icon" :type="item.icon" />
          </template>
          {{ $dt(item, 'name') }}
        </a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
export default {
  name: "layout-sub-menu",
  props: {
    menuInfo: { type: Object, default: () => ({}) },
  },
};
</script>

<style lang="scss">
.layout-sub-menu {

}
</style>
