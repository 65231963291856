<template>
  <div class="device_monitoring" :style="{width: width}">
    <div class="device_monitoring_title">
      <svg-icon name="dishes" />
      <span class="dmt_text">设备实时监控</span>
      <div class="dmt_time">{{ time }}</div>
    </div>
    <a-flex wrap="wrap" :gap="20" class="device_monitoring_items">
      <dashboard-device v-for="(item, index) in list" :key="index" :index="index+1" :value="item" />
    </a-flex>
  </div>
</template>

<script>
// 设备实时监控
import SvgIcon from '@/components/base/svgIcon.vue';
import DashboardDevice from '@/components/index/dashboardDevice.vue';

export default {
  name: 'deviceMonitoring',
  components: { DashboardDevice, SvgIcon },
  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    shopId: { type: String, default: '' },
    list: {
      type: Array,
      default: () => {
        return [
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 2,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 2,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 2,
          },
          {
            img: 'static/deviceImg.svg',
            num: 1234,
            dishes: '小炒牛肉',
            power: '2档',
            status: 1,
          },
        ]
      }
    }
  },
  computed: {
    time() {
      return this.$dayjs().format('YYYY/MM/DD HH:mm');
    }
  },
  methods: {
    // 获取设备列表
    async getDeviceList() {
      this.$ajax({
        url: this.$api.device.list,
      })
    }
  }
};
</script>

<style scoped lang="scss">
.device_monitoring_bg {

}
.device_monitoring {
  border-radius: 24px;
  padding: 24px;
  background: #0F0F19;

  .device_monitoring_title {
    display: flex;
    align-items: center;

    .dmt_text {
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;
      margin-left: 12px;
    }

    .dmt_time {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.4);
      height: 24px;
      display: flex;
      align-items: flex-end;
      margin-left: 8px;
    }
  }
  .device_monitoring_items{
    padding-top: 24px;
  }
}
</style>
