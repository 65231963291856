// created by wangshuyi on 2022/9/16

'use strict';
import { ajax } from 'dolphin-framework-vue';

const requestMixins = {
  props: {
    source: { type: String, default: 'server' },            //数据源，server | local | dict
    //---------------------- if source === dict --------------------------------//
    dictCode: { type: String },                             //数据字典项
    //---------------------- if source === server --------------------------------//
    url: { type: String },                                  //后台地址
    method: { type: String, default: 'post' },              //请求方式
    queryData: { type: Object, default: () => {} },          //请求参数
    queryProperties: { type: Object, default: null },       //Java请求参数
    queryParams: { type: Object, default: () => {} },        //请求参数
    queryPathData: { type: Object, default: () => {} },      //请求参数
    initDataFlag: { type: Boolean, default: true },         //默认是否请求一次数据
    //---------------------- if source === local  --------------------------------//
    defaultData: { type: Array, default: () => [] },

    pagination: { type: Boolean, default: true },            //是否带分页
    //---------------------- if pagination === true --------------------------------//
    paginationType: { type: String, default: 'server' },            //分页类型：前台分页 / 后面分页
    defaultPageSize: { type: Number, default: 10 },                                        //每页条数
    defaultPageNumber: { type: Number, default: 1 },                                       //当前页数
    pageSizeOption: {
      type: Array, default: function () {
        return ['5', '10', '20', '50', '100'];
      }
    },  //可选每页条数
    paginationShowSizeChanger: { Boolean, default: true },
    paginationShowQuickJumper: { Boolean, default: true },
    paginationShowTotal: { Boolean, default: true },
  },
  emits: ['load'],
  data: function () {
    return {
      serverData: {},
      pageSize: this.defaultPageSize,
      pageNumber: this.defaultPageNumber,
      sorter: null,

      searchData: null,                            //组件内条件
      loadingFlag: false,
    };
  },
  computed: {
    innerData() {
      if (this.source === 'server') {
        return this.serverData.rows;
      } else if (this.source === 'dict') {
        return this.serverData.rows;
      } else {
        return this.defaultData;
      }
    },
    innerTotal() {
      if (this.source === 'server') {
        return this.serverData.total;
      } else {
        return this.innerData.length;
      }
    },
  },
  methods: {
    query() {
      this.pageNumber = this.defaultPageNumber;
      return this.load();
    },
    load() {
      switch (this.source) {
        case 'server':
          return this.loadServer();
        case 'dict':
          return this.$dict(this.dictCode).then(data => this.serverData = { rows: data, total: data.length });
      }
    },
    loadServer() {
      let url = this.url;
      let queryParams = {};
      if (this.pagination) {
        queryParams.pageNumber = this.pageNumber;
        queryParams.pageSize = this.pageSize;
      }
      if (this.sorter) {
        queryParams.sortField = this.sorter.field;
        queryParams.sortOrder = this.sorter.order;
      }
      queryParams = Object.assign(queryParams, this.queryParams);

      this.loadingFlag = true;
      let sendData = {};
      if (this.queryProperties) {
        if (this.queryData) {
          this.queryData.properties = this.queryProperties;
          sendData = this.queryData;
        } else {
          sendData = {
            properties: this.queryProperties || {}
          };
        }
      } else {
        sendData = this.queryData;
      }

      if (this.searchData) {
        if (this.method === 'post') {
          sendData = { ...sendData, ...this.searchData };
        } else {
          queryParams = { ...queryParams, ...this.searchData };
        }
      }

      return ajax({
        url: url,
        method: this.method,
        data: sendData,
        params: queryParams,
        pathData: this.queryPathData,
      }).then(data => {
        this.loadingFlag = false;
        this.serverData = data;
        this.$emit('load', data);
        this.finishLoad()
      }).catch(() => {
        this.loadingFlag = false;
      });
    },
    finishLoad(){},
  }
};

export default requestMixins;
