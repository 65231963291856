<template>
  <a-time-picker
     :style="{width:width}"
     v-model:value="time"
     :format="format"
     :allowClear="allowClear"
     :disabled="disabled"
     :placeholder="placeholder"
     :hour-step="hourStep"
     :minute-step="minuteStep"
     :second-step="secondStep"
     :size="size"
     @change="onChange"
  />
</template>

<script>
  import dayjs from 'dayjs';

  export default {
    name: "dol-time",
    props: {
      value: {type: String},
      format: {type: String, default: 'HH:mm:ss'},   //format
      allowClear: {type: Boolean, default: true},     //是否显示清除按钮
      disabled: {type: Boolean, default: false},     //禁用
      placeholder: {type: String, default: '请选择时间'},     //placeholder
      size: {type: String},     //大小large||small
      width: {type: String, default: '100%'},
      hourStep: {type: Number, default: 1},
      minuteStep: {type: Number, default: 1},
      secondStep: {type: Number, default: 1},
    },
    emits: ['update:value', 'change'],
    data() {
      return {
        time: this.value ? this.dayjs(this.value, this.format) : null,
      };
    },
    watch: {
      value(val) {
        val ? this.time = this.dayjs(val, this.format) : this.time = null;
      }
    },
    methods: {
      dayjs,
      onChange(date, dateString) {
        this.$emit('update:value', this.isoDate ? date.toISOString() : dateString);
        this.$emit('change', date, dateString);
      }
    },
    created() {
    }
  }
</script>

<style scoped>

</style>
