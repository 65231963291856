<template>
  <a-layout-footer class="dol-layout-footer">@ {{ $dayjs().format('YYYY') }} Dolphin</a-layout-footer>
</template>

<script>
export default {
  name: "layout-header",
  data(){
    return {
      title: process.env.VUE_APP_WEB_TITLE,
    }
  }
};
</script>

<style lang="scss">
.ant-layout-footer.dol-layout-footer {
  text-align: center;
  padding: 5px;
}
</style>
