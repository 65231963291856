<template>
    <span>
      <dol-upload :url="url" @before-upload="beforeUpload" @finished="importFinished">
        <dol-button shape="default" :loading="importLoading">{{title}}</dol-button>
      </dol-upload>

      <a-modal v-model:open="importErrorMessageModal" width="1000px" :title="importErrorMessage" :footer="null" :mask-closable="false">
        <dol-list
          v-if="importErrorInfo.length > 0"
          :card-flag="false"
          source="local"
          :default-data="importErrorInfo"
          :columns="importErrorColumns"
          :row-index="false"
          row-key="errorIndex"
          ref="list">
          <template #bodyCell="{column, value, record, index}">
            <template v-if="column.dataIndex === 'errorMessage'">
              <span class="color-red">{{ value.join('、') }}</span>
            </template>
          </template>
        </dol-list>
      </a-modal>
    </span>
</template>

<script>
export default {
  name: "dol-import",
  props: {
    title: {type: String, default: '导入'},
    url:{type: String}
  },
  data(){
    return {
      data:'',
      importErrorMessageModal: false,
      importErrorInfo: [],
      importErrorMessage: '',

      importLoading: false,
    }
  },
  computed:{
    importErrorColumns(){
      let columns = [];
      columns.push({ dataIndex: 'errorIndex', title: 'Excel行号' });
      columns.push({ dataIndex: 'errorMessage', title: '错误原因' });

      return columns;
    },
  },
  methods:{
    beforeUpload(){
      this.importLoading = true;
    },
    importFinished(data, resp){
      this.importLoading = false;
      if(resp.success){
        this.$message.info(resp.message);
        this.$emit('finished', data, resp);
      }else{
        this.importErrorInfo = resp.rows;
        this.importErrorMessage = resp.message;
        this.importErrorMessageModal = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
