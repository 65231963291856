<template>
  <a-select
    :value="value"
    :placeholder="placeholder"
    :allow-clear="allowClear"
    :show-search="showSearch"
    :filter-option="false"
    :ref="refName"
    @search="search"
    @change="change"
  >
    <a-select-option v-if="emptyOption" :value="emptyOptionValue">{{ placeholder }}</a-select-option>
    <a-select-option v-for="o in options" :optionData="o" :value="o[valueFieldFinal]" :key="o[keyFieldFinal]">
      <slot name="option" :option="o">
        <div v-html="showLabel(o)"></div>
      </slot>
    </a-select-option>
    <template #suffixIcon>
      <slot name="suffixIcon">
        <dol-icon type="DownOutlined" />
      </slot>
    </template>
  </a-select>
</template>

<script>
import requestMixins from '../common/requestMixins';

export default {
  name: "dol-select",
  mixins: [requestMixins],
  props: {
    value: {},
    keyField: { type: String },        //key  修改了入参的名字以便使用 keyField不传的时候默认会使用valueField
    labelField: { type: String },       //label
    valueField: { type: String },          //value
    searchField: { type: String },          //value

    source: { type: String, default: 'dict' },            //数据源，server | local | dict

    emptyOption: { type: Boolean, default: true },
    emptyOptionValue: { default: null },
    placeholder: { type: String, default: '--请选择--' },

    pagination: { type: Boolean, default: false },

    allowClear: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
    searchType: { type: String, default: 'local' },
    method: { type: String, default: 'get' },

    refName: { type: String },

    i18n: { type: Boolean, default: false },
    customerLabel: {type: Function},
  },
  emits: ['update:value', 'change'],
  data() {
    this.search = this.$Dolphin.debounce(this.search, 500);
    return {
      searchValue: '',
    };
  },
  computed: {
    keyFieldFinal() {
      if (this.keyField) return this.keyField;
      return this.valueFieldFinal;
    },
    labelFieldFinal() {
      if (this.labelField) return this.labelField;
      return 'name';
    },
    valueFieldFinal() {
      if (this.valueField) return this.valueField;
      return this.source === 'server' ? 'id' : 'code';
    },
    searchFieldFinal() {
      if (this.searchField) return this.searchField;
      return this.labelFieldFinal;
    },
    options() {
      if (this.searchType === 'server' || this.searchValue === '') {
        return this.innerData;
      } else {
        return this.innerData.filter(item => {
          const matchLabel = new RegExp(this.searchValue, 'i').test(item[this.labelFieldFinal]);
          const matchValue = this.value instanceof Array ? this.value.find(item[this.valueFieldFinal]) : item[this.valueFieldFinal] === this.value;
          return matchLabel || matchValue;
        });
      }
    }
  },
  watch: {
    url() {
      this.load();
    },
    dictCode() {
      this.load();
    },
  },
  methods: {
    change(value) {
      this.searchValue = '';
      this.$emit('update:value', value);
      let thisOption = this.innerData.find(o => o[this.valueFieldFinal] === value);
      this.$emit('change', value, thisOption);
    },
    search(val) {
      if (this.searchType === 'server') {
        if (val) {
          this.searchData = { [this.searchFieldFinal]: val };
        } else {
          this.searchData = null;
        }
        this.query();
      } else {
        this.searchValue = val;
      }
    },
    // 在load之后执行，处理value有默认值的情况下只显示id，不显示name的情况
    finishLoad() {
      if (this.value && this.searchType === 'server' && this.method === 'post') {
        // 先只处理post
        let queryForm, pageSize;
        const vKey = this.valueFieldFinal;
        if (typeof this.value === 'string') {
          queryForm = { [vKey]: this.value };
          pageSize = 1;
        } else if (this.value instanceof Array) {
          queryForm = { [vKey]: this.value };
          pageSize = this.value.length;
        }
        this.$ajax({ url: this.url, method: 'post', data: queryForm, params: { pageSize } }).then(reData => {
          reData.rows.forEach(it => {
            const index = this.serverData.rows.findIndex(r => r[vKey] === it[vKey]);
            if (index === -1) {
              this.serverData.rows.unshift(it);
            }
          });
        });
      }
    },

    showLabel(option) {
      let value = option[this.labelFieldFinal];
      if(typeof this.customerLabel === 'function'){
        value = this.customerLabel(option);
      }else{
        value = option[this.labelFieldFinal];
      }

      if(option[`${this.labelFieldFinal}_LANG`] && this.currentLang._id !== 'ZH' && option[`${this.labelFieldFinal}_LANG`][this.currentLang._id]){
        value = option[`${this.labelFieldFinal}_LANG`][this.currentLang._id];
      }

      if (!this.searchValue) {
        return value;
      }

      const before = '<span class="color-primary">';
      const after = '</span>';
      return value.replace(new RegExp(`(${this.searchValue})`, 'ig'), before + '$1' + after);
    },
  },
  created() {
    this.query();
  },
};
</script>

<style lang="scss">
.dol-select {

}
</style>
