// created by wangshuyi on 2022/9/1

'use strict';
import router from '@/router';
import sideMenuLayout from '@/layout/sideMenuLayout/layout';
import portalLayout from '@/layout/portalLayout/portal-layout.vue';

const state = {
  cookieName: process.env.VUE_APP_COOKIE_NAME,
  loading: false,

  menuActive: {},
  menuTab: [],

  prompt: {
    title: '请输入',
    desc: null,
    icon: null,
    visible: false,
    type: 'input',
    onOk: null,
    onCancel: null,
    value: null,
  }
};

const mutations = {
  init(state) {
    const sessionStorageStr = sessionStorage.getItem(state.cookieName);
    if (sessionStorageStr) {
      const sessionInfo = JSON.parse(sessionStorageStr);
      for (const module in sessionInfo) {
        if(/lang/.test(module)){
          continue
        }
        for (const field in sessionInfo[module]) {
          if (/loading|prompt/.test(field)) {
            continue;
          }

          this.state[module][field] = sessionInfo[module][field];
        }
      }
    }
  },
  session(state) {
    sessionStorage.setItem(state.cookieName, JSON.stringify(this.state));
  },
  setLoading(state, flag) {
    state.loading = flag;
  },

  setActiveMenu(state, routerTo) {
    const path = routerTo.path;
    const menu = this.state.user.pageList.find(m => m.fullPath === path);
    if (!menu) return;
    menu.routerQuery = routerTo.query;
    const index = state.menuTab.findIndex(m => m.menuId === menu.menuId);
    if (index === -1) state.menuTab.push(menu);
    state.menuActive = menu;
    this.commit('app/session');
  },
  removeMenuTab(state, path) {
    const menu = this.state.user.pageList.find(m => m.fullPath === path);
    if (!menu) return;
    const index = state.menuTab.findIndex(m => m.menuId === menu.menuId);
    if (index !== -1) state.menuTab.splice(index, 1);
    this.commit('app/session');
  },
  removeOtherMenuTab(state, menuId) {
    const res = [];
    state.menuTab.forEach(m => {
      if (m.menuId === menuId) res.push(m);
    });
    state.menuTab = res;
    this.commit('app/session');
  },
  // 页面tab改变sort时触发，同时改变数据的sort
  tabSortChange(state, { oldIndex, newIndex }) {
    const item = state.menuTab.splice(oldIndex, 1);
    state.menuTab.splice(newIndex, 0, item[0]);
    this.commit('app/session');
  },

  initRouteConfig(){
    function getFirstMenu(menu){
      if(menu.children && menu.children.length > 0){
        return getFirstMenu(menu.children[0]);
      }else{
        return menu;
      }
    }
    const firstMenu = getFirstMenu(this.state.user.menuList[0]);

    router.addRoute({
      path: '/',
      component: sideMenuLayout,
      name: 'layout',
      redirect: firstMenu.fullPath,
      children: [
        ...this.state.user.pageList.map(page => ({
          path: page.fullPath,
          name: page.code || page.fullPath,
          component: () => import(`@/views${page.component}`),
        }))
      ]
    });
  },
  getFirstMenu(layout){
    function getFirstMenu(menu){
      if(menu.children && menu.children.length > 0){
        return getFirstMenu(menu.children[0]);
      }else{
        return menu;
      }
    }
    const menuList = layout ? this.state.user.menuList.filter(item => item.layout === layout) : this.state.user.menuList
    return getFirstMenu(menuList[0]);
  }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
