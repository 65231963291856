<template>
  <div class="dol-corn-type">
    <a-form-item-rest>
      表达式模式输入：
      <a-switch v-model:checked="inputFlag" />
    </a-form-item-rest>
  </div>
  <a-input-group compact class="cron-input-group" v-if="!inputFlag">
    <dol-select v-model:value="type" source="local" hide-empty-option :allow-clear="false" :default-data="typeOptions"
                :disabled="disabled" @change="formatValue" />
    <dol-select v-if="/month/.test(type)" v-model:value="day" source="local" hide-empty-option :allow-clear="false"
                :default-data="dayOptions" :disabled="disabled" @change="formatValue" />
    <dol-select v-if="/week/.test(type)" v-model:value="week" source="local" hide-empty-option :allow-clear="false"
                :default-data="weekOptions" :disabled="disabled" @change="formatValue" />
    <dol-time v-if="/month|day|week/.test(type)" v-model:value="time" :disabled="disabled"
              @update:value="formatValue" />
    <dol-time v-if="/hour/.test(type)" v-model:value="minute" format="mm:ss" :disabled="disabled"
              @update:value="formatValue" />
    <dol-select v-if="/everyMinute/.test(type)" v-model:value="everyMinute" hide-empty-option :allow-clear="false"
                source="local" :default-data="everyMinutesOptions" :disabled="disabled" @change="formatValue" />
  </a-input-group>
  <a-input v-else :value="value" @change="inputChange" />
</template>

<script>
export default {
  name: "dol-cron",
  props: {
    value: { type: String },
    disabled: { type: Boolean },
  },
  data() {
    let dayOptions = [];
    for (let i = 1; i <= 28; i++) {
      dayOptions.push({ text: i + '日', code: i });
    }

    let weekOptions = [];
    weekOptions.push({ text: '周一', code: 1 });
    weekOptions.push({ text: '周二', code: 2 });
    weekOptions.push({ text: '周三', code: 3 });
    weekOptions.push({ text: '周四', code: 4 });
    weekOptions.push({ text: '周五', code: 5 });
    weekOptions.push({ text: '周六', code: 6 });
    weekOptions.push({ text: '周日', code: 7 });

    let everyMinutesOptions = [];
    everyMinutesOptions.push({ text: '5分钟', code: '5' });
    everyMinutesOptions.push({ text: '10分钟', code: '10' });
    everyMinutesOptions.push({ text: '15分钟', code: '15' });
    everyMinutesOptions.push({ text: '30分钟', code: '30' });

    return {
      type: 'month',
      day: 1,
      week: 0,
      time: '00:00:00',
      minute: '00:00',
      everyMinute: '5',

      typeOptions: [
        { text: '每月', code: 'month' },
        { text: '每周', code: 'week' },
        { text: '每天', code: 'day' },
        { text: '每小时', code: 'hour' },
        { text: '每隔', code: 'everyMinute' },
      ],
      dayOptions,
      weekOptions,
      everyMinutesOptions,

      inputFlag: false,
    };
  },
  computed: {},
  watch: {
    value() {
      this.parseValue();
    }
  },
  methods: {
    parseValue() {
      if (this.value) {
        const cronPartList = this.value.split(' ');

        if (cronPartList[2] === '*') {
          if (/\*/.test(cronPartList[1])) {
            this.type = 'everyMinute';
          } else {
            this.type = 'hour';
          }
        } else if (cronPartList[3] === '*') {
          this.type = 'day';
        } else if (cronPartList[3] === '?') {
          this.type = 'week';
        } else if (cronPartList[4] === '*') {
          this.type = 'month';
        }

        switch (this.type) {
          case 'month':
            this.day = Number(cronPartList[3]);
          case 'day':
            this.time = `${cronPartList[2]}:${cronPartList[1]}:${cronPartList[0]}`;
            break;
          case 'hour':
            this.minute = `${cronPartList[1]}:${cronPartList[0]}`;
            break;
          case 'everyMinute':
            this.everyMinute = cronPartList[1].replace('*/', '');
            break;
          case 'week':
            this.week = Number(cronPartList[5]);
            break;
        }
      }
    },
    formatValue() {
      let value = ' *';
      switch (this.type) {
        case 'month':
          value = `${this.time.split(':').reverse().join(' ')} ${this.day} * *`;
          break;
        case 'day':
          value = `${this.time.split(':').reverse().join(' ')} * * *`;
          break;
        case 'hour':
          value = `${this.minute.split(':').reverse().join(' ')} * * * *`;
          break;
        case 'week':
          value = `${this.time.split(':').reverse().join(' ')} ? * ${this.week}`;
          break;
        case 'everyMinute':
          value = `0 */${this.everyMinute} * * * *`;
          break;
      }

      this.$emit('update:value', value);
    },
    inputChange(e) {
      this.$emit('update:value', e.target.value);
    },
  },
  created() {
    this.parseValue();
  }
};
</script>

<style lang="scss">
.dol-corn-type {
  margin: 5px 0 12px;
  display: flex;
  align-items: center;
}

.cron-input-group.ant-input-group {
  display: flex;

  .ant-select, .ant-cascader-picker, .ant-time-picker, .ant-input-affix-wrapper {
    width: 130px !important;
    min-width: 130px;
  }
}
</style>
