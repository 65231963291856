<template>
  <a-layout-sider v-show="showMenu" class="dol-layout-sider" :collapsed="menuCollapseFlag" :collapsedWidth="60">
<!--    <div class="system-logo">-->
<!--      <img :src="pcLogo" @error="pcLogoLoadError = true" />-->
<!--    </div>-->
<!--    <div class="dol-menu-collapse" @click="toggleCollapse">-->
<!--      <dol-icon :type="menuCollapse ? 'MenuUnfoldOutlined' : 'MenuFoldOutlined'" />-->
<!--    </div>-->
    <a-menu
      v-model:openKeys="openMenu"
      v-model:selectedKeys="selectMenu"
      mode="inline"
      theme="dark"
    >
      <template v-for="item in childrenMenuList">
        <template v-if="item.children && item.children.length > 0">
          <layout-sub-menu :menu-info="item" />
        </template>
        <template v-else>
          <a-menu-item :key="item.fullPath" @click="$router.push(item.fullPath)">
            <template #icon>
              <dol-icon v-if="item.icon" :type="item.icon" />
            </template>
            {{ $dt(item, 'name') }}
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { mapGetters } from 'vuex';
import layoutSubMenu from './layout-sub-menu';
import defaultIcon from '../../../public/static/icon/favicon.png';

export default {
  name: "layout-sider",
  components: { layoutSubMenu },
  data() {
    return {
      openMenu: [],
      menuCollapse: false,

      pcLogoLoadError: false,
    };
  },
  computed: {
    ...mapGetters(['menuList', 'userInfo', 'tenantInfo']),
    menuCollapseFlag() {
      if (this.childrenMenuList.length === 0) {
        return true;
      } else {
        return this.menuCollapse;
      }
    },
    selectMenu: {
      get() { return [this.$route.path]; },
      set() { },
    },

    childrenMenuList() {
      const topMenu = this.menuList.find(item => item.path === this.$route.path.split('/')[1]);
      return (topMenu && topMenu.children) || [];
    },
    pcLogo(){
      return (this.tenantInfo && this.tenantInfo.imageList && this.tenantInfo.imageList.pcLogo && !this.pcLogoLoadError) ?
        this.$Dolphin.path.publicPath + this.tenantInfo.imageList.pcLogo.filePath :
        defaultIcon
    },
    // 是否展示菜单
    showMenu() {
      return this.childrenMenuList?.length;
    },
  },
  watch: {
    $route(val) {
      this.initOpenMenu();
    },
  },
  methods: {
    toggleCollapse() {
      this.menuCollapse = !this.menuCollapse;
    },
    initOpenMenu() {
      const currentPathList = this.$route.path.split('/');
      let fullPath = '';
      currentPathList.forEach((item, index) => {
        if (index === 0) {
          return true;
        }
        fullPath += '/' + item;
        if (!this.openMenu.find(item => item === fullPath)) {
          this.openMenu.push(fullPath);
        }
      });
    }
  },
  mounted() {
    this.initOpenMenu();
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/constant";

.ant-layout-sider.dol-layout-sider {
  color: $white;
  width: 240px !important;
  max-width: 240px !important;
  flex: 0 0 240px !important;
  height: calc(100vh - 80px);

  .system-logo {
    height: 48px;
    background: $primaryColor;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  .ant-menu-inline {
    .ant-menu-item:not(.ant-menu-item-selected), .ant-menu-submenu:not(.ant-menu-submenu-selected) {
      color: $white_80;
    }
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    margin-inline: 20px;
    width: unset;
    line-height: 56px;
    height: 56px;
    border-radius: 16px;
    font-size: 15px;
  }

  .dol-menu-collapse {
    color: $white;
    padding: 10px;
    border-bottom: 1px solid #41505F;
    text-align: center;
    cursor: pointer;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    .ant-menu-root {
      flex: 1;
      overflow-y: auto;
      padding-bottom: 40px;
    }
  }
}
</style>
