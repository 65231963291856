<template>
  <a-drawer
    class="dol-drawer"
    :open="value"
    :closable="closable"
    :placement="placement"
    :width="width"
    :mask-closable="maskClosable"
    :destroy-on-close="destroyOnClose"
    @close="close"
  >
    <template #title>
      <slot name="title" >{{title}}</slot>
    </template>
    <slot/>
    <template #footer>
      <slot name="footer">
        <div class="dol-drawer-button" v-if="buttonFlag">
          <a-space>
            <a-button @click="close"> {{cancelText}}</a-button>
            <a-button type="primary" :loading="buttonLoading" @click="submit"> {{submitText}}</a-button>
          </a-space>
        </div>
      </slot>
    </template>
  </a-drawer>
</template>

<script>
  export default {
    name: "dol-drawer",
    props: {
      title: {type: String},
      closable: {type: Boolean, default: true},
      destroyOnClose: {type: Boolean, default: false},
      placement: {type: String},
      value: {type: Boolean, default: false},
      width: {default: 600},

      buttonFlag: {type: Boolean, default: false},
      buttonLoading: {type: Boolean, default: false},
      cancelText: {type: String, default: '取消'},
      submitText: {type: String, default: '保存'},
      maskClosable:{type:Boolean, default: false}
    },
    methods: {
      close() {
        console.log('close');
        this.$emit('update:value', false);
      },
      submit() {
        this.$emit('submit');
      },
    }
  }
</script>

<style lang="scss">
.dol-drawer{
  .ant-drawer-footer{
    .dol-drawer-button{
      text-align: right;
    }
  }
}
</style>
