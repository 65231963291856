<template>
  <div class="export-btn">
    <dol-button @click="openDialog"
                :type="buttonType" :size="buttonSize" :ghost="buttonGhost">
      <dol-icon type="download-outlined" />
      {{ buttonText }}
    </dol-button>

    <a-modal v-model:open="exportModal" :mask-closable="false">
      <slot>
        <p>{{ remark }}</p>
      </slot>
      <template v-if="exportFinished">
        <p>如果浏览器未自动下载文件，请尝试点击下方的链接</p>
        <a :href="exportPath" style="text-decoration: underline;">{{ exportName }}</a>
      </template>
      <template #footer>
        <a-button @click="exportModal = false" :disabled="exportDoing">返回</a-button>
        <a-button @click="exportPost" type="primary" :loading="exportDoing"
                  :disabled="exportFinished || exportDoing">
          <dol-icon type="download-outlined" />
          开始导出
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'dol-export',
  props: {
    buttonText: {
      type: String, default: '导出Excel'
    },
    width: { type: String, default: '100%' },
    buttonType: { type: String, default: 'primary' },
    buttonGhost: { type: Boolean, default: true },
    buttonSize: { type: String, default: 'small' },
    smallButton: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    icon: { type: String },
    remark: {
      type: String, default: '导出内容至Excel'
    },
    url: { type: String, default: '' },
    queryData: {
      type: Object, default: () => {
        return {};
      }
    },
    queryParams: {
      type: Object, default: () => {
        return {};
      }
    },
    queryPathData: {
      type: Object, default: () => {
        return {};
      }
    },
    columns: { type: Array, default: () => [] }, // 传给后台的表头
  },
  data() {
    return {
      exportModal: false,
      exportDoing: false,
      exportFinished: false,
      exportPath: '',
      exportName: '',
    };
  },
  methods: {
    openDialog() {
      this.exportModal = true;
      this.exportFinished = false;
    },
    exportPost() {
      this.exportDoing = true;
      const data = Object.assign({}, this.queryData);
      data.portalColumns = this.columns;
      this.$ajax({
        url: this.url,
        method: 'post',
        data: data,
        params: this.queryParams,
        pathData: this.queryPathData,
      }).then(reData => {
        this.exportFinished = true;
        this.exportPath = this.$Dolphin.path.apiPath + reData.data.filePath + '?token=' + this.$store.state.token;
        this.exportName = reData.data.fileName;
        this.$Dolphin.download(this.exportName, this.exportPath);
      }).catch(e => {
        console.log(e);
      }).finally(() => {
        this.exportDoing = false;
      });
    },
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.export-btn {
  display: inline-block;
}
</style>
