//------------------------------------- 页面布局 -------------------------------------//
import portalLayout from '@/layout/portalLayout/portal-layout.vue';

//------------------------------------- 公共模块 -------------------------------------//
import index from '../views/index';
import login from '../views/login/login';
import notFound from '../views/notFound';


const routes = [
  { path: '/login', name: 'login', component: login },

  { path: '/:pathMatch(.*)*', name: 'notFound', component: notFound },
];

const whiteList = [
  /^\/login/, /^\/403/
];

export {
  routes,
  whiteList,
};
