<template>
  <div class="energy" :style="{width: width}">
    <div class="energy_title">能耗监测</div>
    <div class="energy_body"></div>
    <a-flex class="energy_bottom" align="center" :gap="2">
      <svg-icon name="lightning" size="38" />
      <div class="energy_bottom_num">{{ energyConsumption }}</div>
      <div class="energy_bottom_unit">KW</div>
      <div class="energy_bottom_text">{{ dtd }}</div>
    </a-flex>
  </div>
</template>

<script>
// 能耗监测
import SvgIcon from '@/components/base/svgIcon.vue';

export default {
  name: 'energyConsumptionMonitoring',
  components: { SvgIcon },
  props: {
    width: {type: [Number, String], default: '100%'},
    energyConsumption: {type: [Number, String], default: ''}, // 能耗
    dtd: {type: [Number, String], default: '-12%'}, // 天比天增长率
  }
};
</script>

<style scoped lang="scss">
.energy {
  display: flex;
  flex-direction: column;
  height: 230px;
  background: #0177FB;
  border-radius: 24px;
  padding: 18px;
  gap: 12px;
  .energy_title{
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    font-weight: bold;
  }
  .energy_body{
    width: 100%;
    flex: 1;
  }
  .energy_bottom {
    height: 50px;

    .energy_bottom_num{
      font-weight: bold;
      font-size: 42px;
      color: #FFFFFF;
      padding-left: 4px;
    }
    .energy_bottom_unit{
      font-size: 21px;
      color: rgba(0, 0, 0, 0.2);
      line-height: 25px;
      height: 50px;
    }
    .energy_bottom_text{
      font-size: 21px;
      color: #70C787;
    }
  }
}
</style>
