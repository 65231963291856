<script>
import { h } from 'vue';
import { Tooltip, Button } from 'ant-design-vue';
import { DolIcon } from 'dolphin-framework-vue';

export default {
  name: "dol-button",
  inheritAttrs: false,
  props: {
    icon: { type: String },
    title: { type: String },
    loading: { type: Boolean },
  },
  render() {
    const renderButtonContent = (params) => {
      let children = [];
      if (this.icon) {
        children.push(h(DolIcon, { type: this.icon }));
      }
      if (this.$slots.default) {
        children.push(this.$slots.default(params));
      }

      return children;
    };

    let buttonProps = {};
    if (this.title) {
      buttonProps.shape = 'circle';
      buttonProps.size = 'small';
      buttonProps.type = 'primary';
      buttonProps.ghost = !/link|text/.test(this.$attrs.type);
    }
    buttonProps = Object.assign(buttonProps, this.$attrs);
    delete buttonProps.icon;

    if (this.title) {
      return h(Tooltip, { title: this.title }, () => h(Button, buttonProps, renderButtonContent));
    } else {
      return h(Button, buttonProps, renderButtonContent);
    }
  }
};
</script>

<style lang="scss">
.dol-button {

}
</style>
