<template>
  <div>
    <template v-if="inputFlag">{{ value }}</template>
    <template v-else>
      <span>{{ typeOptions[type] || type }}/</span>
      <span v-if="/month/.test(type)">{{ day }}/</span>
      <span v-if="/week/.test(type)">{{ week }}/</span>
      <span v-if="/month|day|week/.test(type)">{{ time }}</span>
      <span v-if="/hour/.test(type)">{{ minute }}</span>
      <span v-if="/everyMinute/.test(type)">{{ everyMinute }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "dol-cron-show",
  props: {
    value: { type: String },
  },
  data() {
    let dayOptions = [];
    for (let i = 1; i <= 28; i++) {
      dayOptions.push({ text: i + '日', code: i });
    }

    let weekOptions = [];
    weekOptions.push({ text: '周一', code: 1 });
    weekOptions.push({ text: '周二', code: 2 });
    weekOptions.push({ text: '周三', code: 3 });
    weekOptions.push({ text: '周四', code: 4 });
    weekOptions.push({ text: '周五', code: 5 });
    weekOptions.push({ text: '周六', code: 6 });
    weekOptions.push({ text: '周日', code: 7 });

    let everyMinutesOptions = [];
    everyMinutesOptions.push({ text: '5分钟', code: '5' });
    everyMinutesOptions.push({ text: '10分钟', code: '10' });
    everyMinutesOptions.push({ text: '15分钟', code: '15' });
    everyMinutesOptions.push({ text: '30分钟', code: '30' });

    return {
      type: 'month',
      day: 1,
      week: 0,
      time: '00:00:00',
      minute: '00:00',
      everyMinute: '5',

      typeOptions: {
        month: '每月',
        week: '每周',
        day: '每天',
        hour: '每小时',
        everyMinute: '每隔',
      },
      dayOptions,
      weekOptions,
      everyMinutesOptions,

      inputFlag: false,
    };
  },
  computed: {},
  watch: {
    value() {
      this.parseValue();
    }
  },
  methods: {
    parseValue() {
      if (!this.value) return '';
      if (/,/.test(this.value)) return this.inputFlag = true;
      const cronPartList = this.value.split(' ');

      if (cronPartList[2] === '*') {
        if (/\*/.test(cronPartList[1])) {
          this.type = 'everyMinute';
        } else {
          this.type = 'hour';
        }
      } else if (cronPartList[3] === '*') {
        this.type = 'day';
      } else if (cronPartList[3] === '?') {
        this.type = 'week';
      } else if (cronPartList[4] === '*') {
        this.type = 'month';
      }

      switch (this.type) {
        case 'month':
          this.day = Number(cronPartList[3]);
          this.day = this.getText(this.day, this.dayOptions);
        case 'day':
          this.time = `${cronPartList[2]}:${cronPartList[1]}:${cronPartList[0]}`;
          this.time = this.formatTime(this.time);
          break;
        case 'hour':
          this.minute = `${cronPartList[1]}:${cronPartList[0]}`;
          this.minute = this.formatTime(this.minute);
          break;
        case 'everyMinute':
          this.everyMinute = cronPartList[1].replace('*/', '');
          this.everyMinute = this.getText(this.everyMinute, this.everyMinutesOptions);
          break;
        case 'week':
          this.week = Number(cronPartList[5]);
          this.week = this.getText(this.week, this.weekOptions);
          break;
      }
    },
    formatTime(time) {
      if (!time) return '';
      let res = '';
      const timeArr = time.split(':');
      timeArr.forEach((t, i) => {
        if (t.length === 1) timeArr[i] = '0' + t;
      });
      return timeArr.join(':');
    },
    getText(code, options) {
      let res = '';
      const item = options.find(it => it.code === code);
      if (item) res = item.text;
      return res;
    },
  },
  created() {
    this.parseValue();
  }
};
</script>

<style lang="scss">
.cron-input-group.ant-input-group {
  .ant-select, .ant-cascader-picker, .ant-time-picker, .ant-input-affix-wrapper {
    width: 130px !important;
    min-width: 130px;
  }
}
</style>
