// 项目组件

import DolExport from './dol-export.vue';
import DolCron from './dol-cron.vue';
import DolCronShow from './dol-cron-show.vue';

export default {
  install(app) {
    app.component(DolExport.name, DolExport);
    app.component(DolCron.name, DolCron);
    app.component(DolCronShow.name, DolCronShow);
  }
};
