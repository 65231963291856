//Created by wangshuyi on 07/03/2018.

'use strict';
import { createI18n } from 'vue-i18n'
const supportLang = /^(zh|en)$/;

const i18n = createI18n({
  globalInjection: true,
  locale: supportLang.test(navigator.language) ? navigator.language : 'zh',
  fallbackLocale: 'zh',
  silentTranslationWarn: true,
  messages:{
    zh: {test: '测试'},
    en: {test: 'Test'},
  }
});

export default i18n;
