<template>
  <a-config-provider :locale="locale" :theme="{token}">
    <div>
      <router-view />
    </div>

    <a-modal v-model:open="prompt.visible" :title="prompt.title" @ok="promptOk" @cancel="promptCancel">
      <div v-if="prompt.desc">{{ prompt.desc }}</div>
      <template v-if="prompt.type === 'dict'">

      </template>
      <template v-else-if="prompt.type === 'textarea'">
        <a-textarea v-model:value="prompt.value" :auto-size="{minRows: 5}" />
      </template>
      <template v-else>
        <a-input v-model:value="prompt.value" />
      </template>
    </a-modal>
  </a-config-provider>
</template>

<script>
import { mapGetters } from "vuex";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import enUS from 'ant-design-vue/es/locale/en_US';

export default {
  data() {
    return {
      confirmValue: '',
      token: {
        colorPrimary: '#0177FB',
        colorPrimaryBg: '#060610',
        colorBgBase: '#060610',
        colorBgLayout: '#060610',
        colorBgContainer: '#060610',
        colorText: "#ffffff",
        colorTextBase: "#ffffff",
        colorBorder: "#2A2A32",
        colorBorderSecondary: "#2A2A32",
        colorInfoBg: "#0177FB",
      },
    };
  },
  computed: {
    ...mapGetters(['prompt', 'userInfo']),
    locale(){
      return this.$i18n.locale === 'zh' ? zhCN : enUS;
    }
  },
  watch: {
    'userInfo.type': {
      handler (val) {
        if(val) {
          this.token.colorPrimary = this.token.colorInfoBg = this.isTenantStaff ? '#0177FB' : '#F2FC89';
          if (this.isTenantStaff) {
            document.getElementsByTagName('body')[0].style.setProperty('--primary-color', '#0177FB')
            document.getElementsByTagName('body')[0].style.setProperty('--font-color', '#FFFFFF')
            document.getElementsByTagName('body')[0].classList.remove('admin_root');
          } else {
            document.getElementsByTagName('body')[0].style.setProperty('--primary-color', '#F2FC89')
            document.getElementsByTagName('body')[0].style.setProperty('--font-color', '#0A0A14')
            document.getElementsByTagName('body')[0].className = 'admin_root';
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    promptOk() {
      if (typeof this.prompt.onOk === 'function') {
        const result = this.prompt.onOk(this.prompt.value);
        if(result instanceof Promise){
          result.then(() => this.prompt.visible = false).catch();
        }else if(result === undefined){
          this.prompt.visible = false;
        }else if(Boolean(result) === true ){
          this.prompt.visible = false;
        }
      }else{
        this.prompt.visible = false;
      }
    },
    promptCancel() {
      if (typeof this.prompt.onCancel === 'function') {
        this.prompt.onCancel(this.prompt.value);
      }
      this.prompt.visible = false;
    },
  },
  created() {
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  display: none;
}
</style>
