<template>
  <a-card v-if="cardFlag" :title="title" :size="cardSize">
    <template #extra><slot name="extra" /></template>
    <a-tree
      class="dol-tree"
      :tree-data="innerData"
      :field-names="fieldNames"
      :check-strictly="checkStrictly"
      :checked-keys="checkedKeys"
      :expanded-keys="finalExpandedKeys"
      @update:expandedKeys="changeExpandedKeys"
      @check="check"
      @select="select"
    >
      <template #title="data"><slot name="title" v-bind="data">{{data.name}}</slot></template>
    </a-tree>
  </a-card>
  <a-tree
    v-else
    class="dol-tree"
    :tree-data="innerData"
    :field-names="fieldNames"
    :check-strictly="checkStrictly"
    :checked-keys="checkedKeys"
    :expanded-keys="finalExpandedKeys"
    @update:expandedKeys="changeExpandedKeys"
    @check="check"
    @select="select"
  >
    <template #title="data"><slot name="title" v-bind="data">{{data.name}}</slot></template>
  </a-tree>
</template>

<script>
import requestMixins from '../common/requestMixins';

export default {
  name: "dol-tree",
  mixins: [requestMixins],
  emits: ['update:checkedKeys', 'update:expandedKeys', 'select'],
  props: {
    checkStrictly: {type: Boolean, default: false},
    checkedKeys: {type: Array, default: () => []},
    expandedKeys: {type: Array},

    pagination: {type: Boolean, default: false},
    method: {type: String, default: 'get'},

    titleField: {type: String, default: 'name'},
    keyField: {type: String, default: 'id'},
    childrenField: {type: String, default: 'children'},

    cardFlag: {type: Boolean, default: false},
    title: {type: String, default: '树'},
    cardSize: {type: String, default: 'small'},
  },
  data(){
    return {
      innerExpandedKeys: [],
    }
  },
  computed: {
    fieldNames(){
      return {title: this.titleField, key: this.keyField, children: this.childrenField}
    },
    finalExpandedKeys(){
      return this.expandedKeys || this.finalExpandedKeys;
    },
  },
  methods: {
    check(checkedKeys, {checked, checkedNodes, node, event}){
      if(this.checkStrictly){
        this.$emit('update:checkedKeys', checkedKeys.checked, {checked, checkedNodes, node, event});
      }else{
        this.$emit('update:checkedKeys', checkedKeys, {checked, checkedNodes, node, event});
      }
    },
    select(selectedKeys, {selected, selectedNodes, node, event}){
      this.$emit('select', selectedKeys, {selected, selectedNodes, node, event});
    },

    changeExpandedKeys(keys){
      if(this.expandedKeys){
        this.$emit('update:expandedKeys', keys);
      }else{
        this.innerExpandedKeys = keys;
      }
    }
  },
  created() {
    this.query();
  }
};
</script>

<style lang="scss">
.dol-tree {

}
</style>
